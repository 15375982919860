
import axios from "axios";
// test 24

// export const baseIP = '160.242.26.156:3000/site_ispaj_back/public'
// export const baseIP = 'ispaj.co.ao/back'
export const baseIP = 'angotransexpress.ao/back'


export const baseURL = `https://${baseIP}/api/v1`
export const imageURL = `https://${baseIP}/public/storage`
const api = axios.create({
    baseURL: baseURL,
})
export default api