import Header from '../components/header';
import '../css/about.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion'
import Footer from '../components/footer';
import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { baseURL, imageURL } from '../api/api';


const Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-down-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6.364 2.5a.5.5 0 0 1 .5-.5H13.5A1.5 1.5 0 0 1 15 3.5v10a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 2 13.5V6.864a.5.5 0 1 1 1 0V13.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5H6.864a.5.5 0 0 1-.5-.5"/>
  <path fill-rule="evenodd" d="M11 10.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1 0-1h3.793L1.146 1.854a.5.5 0 1 1 .708-.708L10 9.293V5.5a.5.5 0 0 1 1 0z"/>
</svg>
  )
}
const Icon2 = () =>  {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building-gear" viewBox="0 0 16 16">
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1z"/>
  <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm4.386 1.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
</svg>
  )
}
const About = () => {
  const location = useLocation()
    const [section, setSection] = useState('Quem Somos')
    const [aboutContent, setAboutContent] = useState(null)

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    
    const icon = useRef(null)
    const [open, setOpen] = useState(false)
    const containerControls = useAnimationControls()
    useEffect(() => {
        if (open) {
            containerControls.start('open')
        }else {
            containerControls.start('close')
        }
    }, [open])
    const menu = useRef(null)
    const swiperRef = useRef(null)
    const handlePrev = () => {
        if (swiperRef.current) swiperRef.current.slidePrev()
    }
    const handleNext = () => {
        if (swiperRef.current) swiperRef.current.slideNext()
    }
    const secondContainerRef = useRef(null)
    useEffect(() => {
      const adjustMargin = () => {
        if (secondContainerRef.current) {
            const outerContainerHeight = document.querySelector('.outerContainer')?.clientHeight || 0;
            secondContainerRef.current.style.marginTop = `${outerContainerHeight}px`;
        }
    };

    const intervalId = setInterval(() => {
        adjustMargin(); // Call the function to adjust margin at regular intervals
    }, 1); // Adjust the interval time as needed

    // Cleanup the interval on component unmount
    return () => {
        clearInterval(intervalId);
    };
    })
    const [valores, setValores] = useState([
      {
          "title": "Segurança",
          "description": "Priorizamos a segurança de nossos passageiros e cargas em todas as operações."
      },
      {
          "title": "Qualidade",
          "description": "Buscamos a excelência em todos os aspectos dos nossos serviços, desde o atendimento até a execução da operação."
      },
      {
          "title": "Pontualidade",
          "description": "Comprometemo-nos a cumprir os prazos estabelecidos, respeitando sempre as necessidades dos nossos clientes."
      },
      {
          "title": "Sustentabilidade",
          "description": "Trabalhamos para reduzir nosso impacto ambiental, adotando práticas sustentáveis em todos os níveis da operação."
      },
      {
          "title": "Inovação",
          "description": "Estamos sempre à frente, utilizando tecnologia de ponta para otimizar a gestão e os processos operacionais."
      }
  ])
    const [services, setServices] = useState([
      {
          "title": "Transporte de Passageiros",
          "description": "A Angotrans oferece soluções de transporte de passageiros com conforto e segurança. Nossa frota moderna garante uma viagem tranquila, seja em deslocamentos regulares ou especiais."
      },
      {
          "title": "Viagens Regulares",
          "description": "Transporte diário para destinos fixos, com horários programados e alta confiabilidade para atender a todas as suas necessidades."
      },
      {
          "title": "Fretamentos",
          "description": "Soluções personalizadas para grupos, eventos corporativos e excursões, com a melhor experiência em conforto e pontualidade garantidas."
      },
      {
          "title": "Turismo",
          "description": "Viagens e passeios turísticos atraentes, explorando diferentes destinos e proporcionando experiências inesquecíveis."
      },
      {
          "title": "Transporte de Cargas",
          "description": "Especializada no transporte de mercadorias, nossa frota é adaptada para diferentes tipos de carga, atendendo a diversas indústrias."
      },
      {
          "title": "Transporte de Mercadorias",
          "description": "Realizamos carga geral, cargas fracionadas e também transporte de grandes volumes com total segurança e eficiência."
      },
      {
          "title": "Transporte de Cargas Especiais",
          "description": "Proporcionamos transporte de produtos frágeis, produtos perigosos e cargas refrigeradas com total cuidado e segurança."
      },
      {
          "title": "Soluções Logísticas",
          "description": "Serviços de logística integrada, oferecendo soluções personalizadas que atendem de forma eficaz cada necessidade específica."
      }
  ])
  const [diffs, setDiffs] = useState([
    {
        "title": "Otimização de Rotas",
        "description": "Utilizamos ferramentas inovadoras para reduzir o tempo de viagem e o consumo de combustível, garantindo eficiência e eficácia nas operações."
    },
    {
        "title": "Gestão Ambiental",
        "description": "Implementamos práticas de reciclagem e redução de resíduos operacionais, comprometendo-nos com as melhores normas ambientais."
    },
    {
        "title": "Frota Moderna e Variada",
        "description": "Nossa frota diversificada possibilita oferecer soluções específicas para diversos tipos de transporte, tanto passageiros quanto cargas."
    },
    {
        "title": "Equipe Qualificada",
        "description": "Contamos com motoristas experientes e bem treinados, que priorizam a segurança e o excelente atendimento ao cliente em todas as viagens."
    },
    {
        "title": "Tecnologia de Ponta",
        "description": "Utilizamos sistemas de rastreamento em tempo real e otimização de rotas, assegurando eficiência e controle durante as operações."
    },
    {
        "title": "Atendimento Personalizado",
        "description": "Oferecemos soluções sob medida que atendem às necessidades específicas de cada cliente, com flexibilidade e adaptação garantidas."
    }
])
const [activeSection, setActiveSection] = useState('')
const [info, setInfo] = useState(null)
const sectionsRef = useRef([])


  useEffect(() => {
    window.scrollTo(0, 0)
    axios.get(`${baseURL}/aboutcontents`)
    .then(res => {
        let content = [...res.data][[...res.data].length - 1]
        setAboutContent(content)
    })

    axios.get(`${baseURL}/empresa/info`)
    .then(res => {
      let content = [...res.data][[...res.data].length - 1]
      setInfo(content)
    })

  }, [])
  const scrollToSection = (title) => {
    const sections = document.querySelectorAll('.title');
    setActiveSection(title)
    sections.forEach(section => {
      if (section.textContent == 'Quem Somos'){
        window.scrollTo({behavior: 'smooth', top: 0})
      }
      else if (section.textContent === title) {
        const offsetPosition = section.getBoundingClientRect().top + window.pageYOffset - (window.innerWidth <= 862 ? 110 : 95);
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    });
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const currentSection = params.get('section');
    if (currentSection) {
      setActiveSection(currentSection);
      scrollToSection(currentSection);
    }else {
      setActiveSection('Quem Somos');

    }

    // const handleScroll = () => {
    //   sectionsRef.current.forEach((section) => {
    //     const rect = section.getBoundingClientRect();
        

    //     if (rect.top >= 0 && rect.top <= window.innerHeight) {
    //       const url = new URL(window.location);
    //       url.searchParams.set('section', section?.textContent);
    //       window.history.pushState({}, '', url);
       
    //       // setActiveSection(section.textContent);
    //       // console.log('active', section.textContent )
    //     }
    //   });
    // };

    // window.addEventListener('scroll', handleScroll);
    return () => {
      // window.removeEventListener('scroll', handleScroll);
    };
  }, [location.search]);
    return (
        <div id='about' className='homeBody1'>
            <Header />
            <div className='swiperContainer' style={{display: 'none'}}>
            <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        loop
        slidesPerView={1}
        onChange={() => {
            //('changed')
        }}
        onSlideChange={(e) => {
            // //('changeddddd', e.realIndex)
            setActiveIndex(e.realIndex)
        }}
        
        pagination={{
          clickable: true,
          
        }}
        onSwiper={(e) => {
            swiperRef.current = e
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className='slideTextCont'>{activeIndex == 0 && <motion.div initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{duration: 0.8}} className='slideText'>Seja bem vindo ao Instituto Superior Politécnico Alvorecer Da Juventude</motion.div>}</div>

          <img loading="lazy"src={process.env.PUBLIC_URL + '/images/bus.jpg'} />
        </SwiperSlide>
        {/* <SwiperSlide>
        <div className='slideTextCont'>{activeIndex == 1 && <motion.div initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{duration: 0.8}} className='slideText'>Um lugar de descoberta, crescimento e sucesso</motion.div>}</div>

          <img loading="lazy"src={process.env.PUBLIC_URL + '/images/banner2.jpg'}  />

        </SwiperSlide> */}
        <SwiperSlide>
        <div className='slideTextCont'>{activeIndex == 2 && <motion.div initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{duration: 0.8}} className='slideText'>Educação transformadora</motion.div>}</div>

          <img loading="lazy"src={process.env.PUBLIC_URL + '/images/bus.jpg'}  />

        </SwiperSlide>
        <div onClick={() => handlePrev()} className='button left'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg></div>
        <div onClick={() => handleNext()} className='button right'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
</svg></div>
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
            </div>
            <div className='aboutContainer' style={{marginTop: 80}}>
              <div className='left'>
              <div className='sideMenu'>
            {['Quem Somos', 'Missão e Visão', 'Valores', 'Nossos serviços', 'Nossa frota', 'Compromisso com segurança', 'Nossos Diferenciais', 'Contactos'].map((item, index) => (
              <div
                key={index}
                className={activeSection === item ? 'menuItem1' : 'menuItem'}
                onClick={() => {
                  scrollToSection(item);
                  const url = new URL(window.location);
                  url.searchParams.set('section', item);
                  window.history.pushState({}, '', url);
                }}
              >
                <Icon /> {item}
              </div>
            ))}
            </div>
              </div>
              <div className='right'>
                <StaggeredDropDown section={section} setSection={setSection} setActiveSection={setActiveSection} sectionsRef={sectionsRef}/>

                <section className='aboutSection'>
                  <div className='aboutInfo'>
                    <div className='title' ref={(el) => (sectionsRef.current[0] = el)}>Quem Somos</div>
                    {/* <p>A Angotrans é uma empresa especializada no transporte rodoviário, oferecendo soluções de transporte de passageiros e cargas com uma frota moderna e diversificada. Nosso compromisso é proporcionar serviços eficientes, seguros e pontuais, atendendo às necessidades de nossos clientes com excelência e responsabilidade.</p> */}
                    {!aboutContent?.somos ? <p>
                      <Skeleton count={2}/>
                    </p>: 
                    <p>
                      {aboutContent?.somos}
                    </p>}
                  </div>
                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
                <section className='aboutSection'>
                  <div className='aboutInfo'>
                  <div className='title' ref={(el) => (sectionsRef.current[1] = el)}>Missão e Visão</div>
                  {aboutContent?.visao ? <p>Missão: {aboutContent?.missao}</p> :
                  <p>
                    <Skeleton count={2}/>
                  </p>}
                  {aboutContent?.visao ? <p>Visão: {aboutContent?.visao}</p> :
                  <p>
                    <Skeleton count={2}/>
                  </p>}

                  </div>
                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
                <section className='aboutSection'>
                  <div className='aboutInfo'>
                    <div className='title' ref={(el) => (sectionsRef.current[2] = el)}>Valores</div>
                    <div className='values'>
                      {aboutContent?.valores && aboutContent?.valores?.length > 0 ?
                        aboutContent?.valores.map((item, index) => {
                          return (
                            <div className='value' key={index}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-suit-diamond" viewBox="0 0 16 16">
  <path d="M8.384 1.226a.463.463 0 0 0-.768 0l-4.56 6.468a.54.54 0 0 0 0 .612l4.56 6.469a.463.463 0 0 0 .768 0l4.56-6.469a.54.54 0 0 0 0-.612zM6.848.613a1.39 1.39 0 0 1 2.304 0l4.56 6.468a1.61 1.61 0 0 1 0 1.838l-4.56 6.468a1.39 1.39 0 0 1-2.304 0L2.288 8.92a1.61 1.61 0 0 1 0-1.838z"/>
</svg>
                            <div className='valueTitle'>
                              {item?.nome}
                            </div>
                            <div className='valueDescription'>
                              {item?.descricao}
                            </div>
                            </div>
                          )
                        })
                      :
                      valores.map((item, index) => {
                        return (
                          <div className='value' key={index}>
                              
<Skeleton style={{width: 30, height: 30}}/>
                          <p>
                            <Skeleton className='valuetitle'/>
                          </p>
                          <p>
                            <Skeleton className='valueDescription' count={3} style={{marginBlock: 0, height: 10}}/>
                          </p>
                          </div>
                        )
                      })
                      }
                    </div>
                  </div>
                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
                <section className='aboutSection'>
                  <div className='aboutInfo'>
                  <div className='title' ref={(el) => (sectionsRef.current[3] = el)}>Nossos serviços</div>
                  <div className='services'>
                      {aboutContent?.services && aboutContent?.services?.length > 0 ?
                        aboutContent?.services.map((item, index) => {
                          return (
                            <div className='value' key={index}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-suit-diamond" viewBox="0 0 16 16">
  <path d="M8.384 1.226a.463.463 0 0 0-.768 0l-4.56 6.468a.54.54 0 0 0 0 .612l4.56 6.469a.463.463 0 0 0 .768 0l4.56-6.469a.54.54 0 0 0 0-.612zM6.848.613a1.39 1.39 0 0 1 2.304 0l4.56 6.468a1.61 1.61 0 0 1 0 1.838l-4.56 6.468a1.39 1.39 0 0 1-2.304 0L2.288 8.92a1.61 1.61 0 0 1 0-1.838z"/>
</svg>
                            <div className='valueTitle'>
                              {item?.nome}
                            </div>
                            <div className='valueDescription'>
                              {item?.descricao}
                            </div>
                            </div>
                          )
                        })
                      :
                      services.map((item, index) => {
                        return (
                          <div className='value' key={index}>
                              
<Skeleton style={{width: 30, height: 30}}/>
                          <p>
                            <Skeleton className='valuetitle'/>
                          </p>
                          <p>
                            <Skeleton className='valueDescription' count={3} style={{marginBlock: 0, height: 10}}/>
                          </p>
                          </div>
                        )
                      })}
                    </div>

                  </div>
                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
                <section className='aboutSection'>
                  <div className='aboutInfo'>
                    <div className='title' ref={(el) => (sectionsRef.current[4] = el)}>Nossa frota</div>
                    <p>A frota da Angotrans é composta por veículos modernos e bem mantidos, garantindo a segurança e o conforto em todas as operações. Contamos com:</p>

                    <div className='images'>
                      {
                        aboutContent?.frota && aboutContent?.frota?.length > 0 ?
                        [...aboutContent?.frota].map((item) => {
                          return (
                            <div className='imageContainer'>
                        <img src={`${imageURL}/${item?.imagem}`}/>
                        <div className='info'>
                          <div className='infoTitle'>{item?.titulo}</div>
                          <div className='infoDescription'>{item?.description}</div>
                        </div>
                      </div>
                          )
                        })
                        :
                        [1, 1].map((i) => {
                          return (
                            <Skeleton className='imageContainer'>
                        {/* <img src={process.env.PUBLIC_URL + '/images/trucks.webp'}/> */}
                        <div className='info'>
                          <div className='infoTitle'>Camiões</div>
                          <div className='infoDescription'>Veículos de última geração, com configurações que atendem tanto a viagens de longa distância quanto fretamentos especiais</div>
                        </div>
                      </Skeleton>
                          )
                        })
                      }
                    </div>
                    
                    <p>Todos os nossos veículos passam por rigorosos processos de manutenção preventiva e estão equipados com tecnologia de monitoramento em tempo real, o que nos permite garantir a segurança e eficiência nas operações.</p>
                  </div>

                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
                <section className='aboutSection'>
                  <div className='aboutInfo'>
                    <div className='title' ref={(el) => (sectionsRef.current[5] = el)}>Compromisso com segurança</div>
                    <p>Na Angotrans, a segurança é uma prioridade absoluta. Nossos motoristas são altamente qualificados, recebendo treinamentos contínuos para garantir a condução responsável e segura. Além disso, nossa frota é equipada com sistemas de segurança avançados, como rastreamento via GPS, câmeras de monitoramento, e tecnologia de controle de tráfego, permitindo uma gestão mais eficiente e segura das operações.</p>
                  </div>
                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
                <section className='aboutSection'>
                  <div className='aboutInfo'>
                    <div className='title' ref={(el) => (sectionsRef.current[6] = el)}>Nossos Diferenciais</div>
                    <p>Na Angotrans, a segurança é uma prioridade absoluta. Nossos motoristas são altamente qualificados, recebendo treinamentos contínuos para garantir a condução responsável e segura. Além disso, nossa frota é equipada com sistemas de segurança avançados, como rastreamento via GPS, câmeras de monitoramento, e tecnologia de controle de tráfego, permitindo uma gestão mais eficiente e segura das operações.</p>

                    <div className='values'>
                      {aboutContent?.diferencias && aboutContent?.diferencias?.length > 0 ?
                        aboutContent?.diferencias.map((item, index) => {
                          return (
                            <div className='value' key={index}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-suit-diamond" viewBox="0 0 16 16">
  <path d="M8.384 1.226a.463.463 0 0 0-.768 0l-4.56 6.468a.54.54 0 0 0 0 .612l4.56 6.469a.463.463 0 0 0 .768 0l4.56-6.469a.54.54 0 0 0 0-.612zM6.848.613a1.39 1.39 0 0 1 2.304 0l4.56 6.468a1.61 1.61 0 0 1 0 1.838l-4.56 6.468a1.39 1.39 0 0 1-2.304 0L2.288 8.92a1.61 1.61 0 0 1 0-1.838z"/>
</svg>
                            <div className='valueTitle'>
                              {item?.nome}
                            </div>
                            <div className='valueDescription'>
                              {item?.descricao}
                            </div>
                            </div>
                          )
                        })
                        :
                        diffs.map((item, index) => {
                          return (
                            <div className='value' key={index}>
                                
  <Skeleton style={{width: 30, height: 30}}/>
                            <p>
                              <Skeleton className='valuetitle'/>
                            </p>
                            <p>
                              <Skeleton className='valueDescription' count={3} style={{marginBlock: 0, height: 10}}/>
                            </p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
                <section className='aboutSection'>
                  <div className='aboutInfo'>
                    <div className='title' ref={(el) => (sectionsRef.current[7] = el)}>Contactos</div>
                    <div className='contactsContainer'>
                      <div className='contacts'>
                        <div className='contact'>
                          <label>Email 1</label>
                          {info?.info?.email ? <span style={{cursor: 'pointer'}} onClick={() => {
                            window.open(`mailto:${info?.info?.email}`)
                          }}>{info?.info?.info}</span>: 
                          <span>
                            <Skeleton />
                            </span>}
                        </div>
                        
                        <div className='contact'>
                          <label>Telefone 1</label>
                          {info?.info?.numero ?
                          <span>+244 {info?.info?.numero}</span>
                        :
                          <span>
                            <Skeleton />
                            </span>}
                        </div>
                        <div className='contact'>
                          <label>Telefone 2</label>
                          {info?.info?.numero2 ?
                          <span>+244 {info?.info?.numero2}</span>
                        :
                          <span>
                            <Skeleton />
                            </span>}                        
                          </div>
                        <div className='contact'>
                          <label>Endereço</label>
                          <span>Rua do Cemitério de Viana, Junto ao Estaleiro CME, Distrito Urbano do Kikuxi, Viana.</span>
                        </div>
                      </div>
                      <div className='iframe'>
                      {info?.info?.localizacao ? <div style={{width: '100%', height: '100%'}} dangerouslySetInnerHTML={{__html: info?.info?.localizacao}}>
                      </div> :
                      <Skeleton style={{width: '100%', height: '100%'}}/>}
                      </div>
                    </div>
                  </div>
                  <div className='aboutImageContainer'>
                    
                  </div>
                </section>
              </div>
            </div>
            <Footer />
        </div>
    )
}
const StaggeredDropDown = ({section, setSection, setActiveSection, sectionsRef}) => {
    
  const [open, setOpen] = useState(false);
  useEffect(() => {
    //('open', open)
  }, [open])
  const menuRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)){
            setOpen(false)
        }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
        document.removeEventListener('mousedown', handleClickOutside)
    }
}, [])

  return (
    <div ref={menuRef} style={{zIndex: 10}} className="aboutOptions">
      <motion.div animate={open ? "open" : "closed"} className="relative">
        <button
          onClick={() => setOpen((pv) => !pv)}
          className="aboutOptionsButton"
        >
          <span className="aboutOptionsText">Navegação Adicional</span>
          <motion.span variants={iconVariants}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
</svg>
          </motion.span>
        </button>

        <motion.ul
          initial={wrapperVariants.closed}
          variants={wrapperVariants}
          style={{ originY: "top", translateX: 0}}
          className="aboutOptionsList"
        >
          {['Quem Somos', 'Missão e Visão', 'Valores', 'Nossos serviços', 'Nossa frota', 'Compromisso com segurança', 'Nossos Diferenciais', 'Contactos'].map((item) => {
            return (
              <Option setOpen={setOpen} section={section} setSection={setSection} text={item} setActiveSection={setActiveSection} sectionsRef={sectionsRef}/>
            )
          })}
          
        </motion.ul>
      </motion.div>
    </div>
  );
};
const Option = ({ text, section, setSection, setOpen, setActiveSection, sectionsRef}) => {
  const scrollToSection = (title) => {
    const sections = document.querySelectorAll('.title');
    setActiveSection(title)
    sections.forEach(section => {
      if (section.textContent == 'Quem Somos'){
        window.scrollTo({behavior: 'smooth', top: 0})
      }
      else if (section.textContent === title) {
        const offsetPosition = section.getBoundingClientRect().top + window.pageYOffset - (window.innerWidth <= 862 ? 110 : 85);
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    });
  };
  return (
    <motion.li
      variants={itemVariants}
      onClick={() => {
        setOpen(false)
        !section.includes(text) && setSection(text)
        scrollToSection(text);
        const url = new URL(window.location);
        url.searchParams.set('section', text);
        window.history.pushState({}, '', url);
        
      }}
      className={section.includes(text) ? 'optionItem1' : 'optionItem'}
    >
      
      <Icon /> <span>{text}</span>
    </motion.li>
  );
};
const actionIconVariants = {
  open: { scale: 1, y: 0 },
  closed: { scale: 0, y: -7 },
};
const wrapperVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const iconVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    y: -15,
    transition: {
      when: "afterChildren",
    },
  },
};
export default About