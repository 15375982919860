import { Link, useLocation, useNavigate } from "react-router-dom"
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion'
import '../css/header.css'
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
  } from "../@/components/ui/navigation-menu"
import { cn } from "../@/lib/utils"
import { forwardRef, useEffect, useRef, useState } from "react"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../@/components/ui/sheet"
import axios from "axios"
import { ScrollArea } from "../@/components/ui/scroll-area"
import { baseURL } from "../api/api"
import Skeleton from "react-loading-skeleton"
import { Dialog, DialogContent, DialogTrigger } from "../@/components/ui/dialog"
import Lottie from "lottie-react"



const Header = () => {
    const icon = useRef(null)
    const location = useLocation()
    const navigate = useNavigate()
    const [selected, setSelected] = useState(0)
    const [selectedSide, setSelectedSide] = useState(0)
    const [open, setOpen] = useState(false)
    const sideMenuRef = useRef(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [destinations1, setDestinations1] = useState([
      { name: "Rotunda Gamek", capital: "Mercado São Paulo", image: 'https://picsum.photos/id/277/1280/900.webp' },
  { name: "Vila do Gamek", capital: "Largo 4 de Fevereiro", image: 'https://picsum.photos/id/251/1280/900.webp' },
  { name: "Control Golf 2", capital: "Vila do Gamek - Pedalê", image: 'https://picsum.photos/id/66/1280/900.webp' },
  { name: "Control Golf 2", capital: "Benfica", image: 'https://picsum.photos/id/350/1280/900.webp' },
  { name: "Vila Estoril", capital: "Cuca", image: 'https://picsum.photos/id/221/1280/900.webp' },
  { name: "Benfica", capital: "Shoprite", image: 'https://picsum.photos/id/251/1280/900.webp' },
  { name: "Benfica", capital: "1º de Maio", image: 'https://picsum.photos/id/313/1280/900.webp' },
  { name: "Benfica", capital: "Mutamba", image: 'https://picsum.photos/id/15/1280/900.webp' },
  { name: "Correios", capital: "Mutamba", image: 'https://picsum.photos/id/168/1280/900.webp' },
 
  // { name: "Bengo", capital: "Caxito" },
  // { name: "Cabinda", capital: "Cabinda" },
  // { name: "Zaire", capital: "Mbanza Congo" },
  // { name: "Uíge", capital: "Uíge" },
  // { name: "Cuando Cubango", capital: "Menongue" }
  ])
    const [destinations, setDestinations] = useState([
        { name: "Luanda", capital: "Luanda", image: 'https://picsum.photos/id/277/1280/900.webp' },
    { name: "Benguela", capital: "Benguela", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Huambo", capital: "Huambo", image: 'https://picsum.photos/id/66/1280/900.webp' },
    { name: "Huíla", capital: "Lubango", image: 'https://picsum.photos/id/350/1280/900.webp' },
    { name: "Namibe", capital: "Moçâmedes", image: 'https://picsum.photos/id/221/1280/900.webp' },
    { name: "Kwanza Norte", capital: "N'dalatando", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Kwanza Sul", capital: "Sumbe", image: 'https://picsum.photos/id/313/1280/900.webp' },
    { name: "Malanje", capital: "Malanje", image: 'https://picsum.photos/id/15/1280/900.webp' },
    { name: "Lunda Norte", capital: "Lunda", image: 'https://picsum.photos/id/168/1280/900.webp' },
    { name: "Lunda Sul", capital: "Saurimo", image: 'https://picsum.photos/id/368/1280/900.webp' },
    { name: "Moxico", capital: "Luena", image: 'https://picsum.photos/id/542/1280/900.webp' },
    { name: "Cunene", capital: "Ondjiva", image: 'https://picsum.photos/id/851/1280/900.webp' },
    // { name: "Bengo", capital: "Caxito" },
    // { name: "Cabinda", capital: "Cabinda" },
    // { name: "Zaire", capital: "Mbanza Congo" },
    // { name: "Uíge", capital: "Uíge" },
    // { name: "Cuando Cubango", capital: "Menongue" }
    ])
    const [components, setComponents]= useState([{
        title: "Huambo",
        href: "",
        description:
          "Venda de passagens partindo de Huambo",
      },
      {
        title: "Huíla",
        href: "/docs/primitives/hover-card",
        description:
          "For sighted users to preview content available behind a link.",
      },
      {
        title: "Namibe",
        href: "/docs/primitives/progress",
        description:
          "Displays an indicator showing the completion progress of a task, typically displayed as a progress bar.",
      },
      {
        title: "Kwanza Norte",
        href: "/docs/primitives/scroll-area",
        description: "Visually or semantically separates content.",
      },
      {
        title: "Kwanza Sul",
        href: "/docs/primitives/tabs",
        description:
          "A set of layered sections of content—known as tab panels—that are displayed one at a time.",
      },
      {
        title: "Veja mais",
        href: "",
        description:
          "",
      },
    ])
    const [departments, setDepartments] = useState([])
    const [rotas, setRotas] = useState([])
    const [rotasExpress, setRotasExpress] = useState([])
    useEffect(() => {
      axios.get(`${baseURL}/rotasUrbanas`)
      .then(res => {
        setRotas(res.data)
      })
      axios.get(`${baseURL}/rotasExpress`)
      .then(res => {
        setRotasExpress(res.data)
      })
    }, [])
    return (
        <div className="header">
          <Dialog open={dialogOpen} onOpenChange={(e) => {
                setDialogOpen(e)
            }} >
                <DialogTrigger style={{display: 'none'}}>

                </DialogTrigger>
                <DialogContent style={{width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column',}}>
                <Lottie rendererSettings={
                    {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                    } autoplay loop animationData={require('../components/loading6.json')}style={{width: 250, height: 250, alignSelf: 'center', marginTop: 40}} className='lottieItem'/>

                    <div style={{textAlign: 'center', fontSize: 20, color: '#495057', fontWeight: '500', marginTop: 30}}>Página em manutenção. Ele estará disponivel em breve</div>
                    <div style={{textAlign: 'center', color: 'grey', fontSize: 14}}>Esta página estará disponivel em breve. Por enquanto subsreva-se ao nosso boletim informativo para ser notificado assim que o aplicativo for lançado.</div>

                    <div style={{paddingTop: 20, width: '100%', borderTop: '2px solid #e6e6e6'}}></div>

                </DialogContent>
            </Dialog>
            <div className='container'>
                <div className='logoTextCont'>
                    <div onClick={() => location.pathname != '/' && navigate('/')} className='logoText'><span style={{color: '#0389B0'}}>Ango</span><span style={{color: '#9D090C'}}>Trans</span> <span style={{fontSize: 10, fontWeight: 500, color: '#0389B0', textTransform: 'lowercase'}}>express</span></div>
                    
                </div>
                <NavigationMenu className='navMenu'>
      <NavigationMenuList>
      
      <NavigationMenuItem>
          <NavigationMenuTrigger className={location.pathname.includes('urbano') ? 'navigationTriggers1' : 'navigationTriggers'}onClick={() => {
            navigate('/urbano')
          }}>Urbano</NavigationMenuTrigger>
          <NavigationMenuContent>
          <ul className="aboutMenu" style={{width: 300, minWidth: 300}}>
            {rotas.length > 0 ? rotas.map((item, index) => {
                return (
                    <div className="menuItem" onClick={() => {
                      navigate('/urbano')

                    }}>{item?.partida}  &#8594; {item?.chegada}</div>
                )
            }):
            destinations1.map((item, index) => {
              return (
                  // <div className="menuItem">{item?.name}  &#8594; {item?.capital}</div>
                  <p>
                    <Skeleton count={1} style={{marginBlock: 10}}/>
                  </p>
              )
          })}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className='navigationTriggers' onClick={() => {
            navigate('/express')
            // setDialogOpen(true)
          }}>Express</NavigationMenuTrigger>
          <NavigationMenuContent>
          <ul className="aboutMenu" style={{width: 300, minWidth: 300}}>
          {rotasExpress.length > 0 ? rotasExpress.map((item, index) => {
                return (
                    <div onClick={() => {
                      navigate('/express')
                    }} className="menuItem">{item?.partida}  &#8594; {item?.chegada}</div>
                )
            }):
            destinations1.map((item, index) => {
              return (
                  // <div className="menuItem">{item?.name}  &#8594; {item?.capital}</div>
                  <p>
                    <Skeleton count={1} style={{marginBlock: 10}}/>
                  </p>
              )
          })}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className={location.pathname.includes('interprovincial') ? 'navigationTriggers1' : 'navigationTriggers'}onClick={() => {
            // navigate('/interprovincial')
            setDialogOpen(true)
          }}>Inter-províncial</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {components.map((component) => (
                <ListItem
                  key={component.title}
                  title={component.title}
                  href={component.href}
                  setDialogOpen={setDialogOpen}
                >
                  {component.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        {/* <NavigationMenuItem>
          <Link href="/docs" legacyBehavior passHref>
            <NavigationMenuLink className={"navigationTriggers "+navigationMenuTriggerStyle()}>
            Recrutamento
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem> */}
        
      </NavigationMenuList>
    </NavigationMenu>

            <NavigationMenu className='navMenu'>
                <NavigationMenuList>
                <NavigationMenuItem>
          <NavigationMenuTrigger className={location.pathname.includes('sobre') ? 'navigationTriggers1' : 'navigationTriggers'}onClick={() => {
            navigate('/sobre')
          }}>Sobre</NavigationMenuTrigger>
          <NavigationMenuContent>
          <ul className="aboutMenu">
            <div className="menuItem" onClick={() => navigate('/sobre?section=Quem+Somos')}>Quem Somos</div>
            <div className="menuItem" onClick={() => navigate('/sobre?section=Missão+e+Visão')}>Missão e Visão</div>
            <div className="menuItem" onClick={() => navigate('/sobre?section=Valores')}>Valores</div>
            <div className="menuItem" onClick={() => navigate('/sobre?section=Nossos+serviços')}>Nossos serviços</div>
            <div className="menuItem" onClick={() => navigate('/sobre?section=Nossa+frota')}>Nossa frota</div>
            <div className="menuItem" onClick={() => navigate('/sobre?section=Compromisso+com+segurança')}>Compromisso</div>
            <div className="menuItem" onClick={() => navigate('/sobre?section=Nossos+Diferenciais')}>Nossos diferenciais</div>
            <div className="menuItem" onClick={() => navigate('/sobre?section=Contactos')}>Contactos</div>

            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/noticias"  to={'/noticias'} legacyBehavior passHref>
            <NavigationMenuLink className={"navigationTriggers "+navigationMenuTriggerStyle()} style={{fontSize: 13}}
            >
              Notícias
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenu>
            <Sheet style={{zIndex: 111000100101, outline: 'none', border: 'none'}} open={open} onOpenChange={(e)=> {
              setOpen(e)
            }}>
                            <SheetTrigger style={{outline: 'none', border: 'none', boxShadow: 'none'}} onClick={()=> {
                                setSelectedSide(0)
                                setOpen(true)
                            }} className="bi-list">
                                <div  style={{background: '#e6e6e677', padding: 10, borderRadius: 10}} ref={icon} onClick={() => {
                                    // setOpen(!open)
                                     }} className={'bi-list'}>
                                <svg ref={icon} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    
                                </div>
                            </SheetTrigger>
                            <SheetContent style={{zIndex: 2010129391831298, padding: 0, paddingTop: 25, overflow: 'hidden'}}>
                                <SheetHeader>
                                    <SheetTitle></SheetTitle>
                                </SheetHeader>
                                <motion.div ref={sideMenuRef}
                className='sideMenuContainer flex flex-col z-10 gap-5 h-full '>
                    <div className='sideMenu flex flex-col w-full'>
                    <div className='sideHeader' style={{}}>
                            {<motion.img initial={{
                                opacity: 0,
                                y: 0,
                            }}
                            animate={{
                                opacity: 1,
                                y: 0,
                            }}
                            exit={{
                                opacity: 0,
                                y: 0
                            }}
                            transition={{
                                duration: 0.5,
                                ease: 'easeInOut',
                            }}
                             className='sideLogo' src={process.env.PUBLIC_URL+'/images/logo.png'} />}
                            {/* {<motion.div initial={{
                                opacity: 0,
                                y: 0,
                            }}
                            animate={{
                                opacity: 1,
                                y: 0,
                            }}
                            exit={{
                                opacity: 0,
                                y: 0
                            }}
                            transition={{
                                duration: 0.5,
                                ease: 'easeInOut',
                            }}
                            className='sideText' style={{marginLeft: 5}}><span style={{color: '#0389B0'}}>Ango</span><span style={{color: '#9D090C'}}>Trans</span> <span style={{fontSize: 10, fontWeight: 500, color: '#0389B0', textTransform: 'lowercase'}}>express</span></motion.div>} */}
                        </div>
                        <ScrollArea style={{height: '88vh'}}>
                        {selectedSide == 0 ? <motion.div
                        initial={{
                            opacity: 0,
                            x: -100
                        }}
                        animate={{
                            opacity: 1,
                            x: 0
                        }}
                         className='sideMenuItems'>
                            <div onClick={() => {
                                setSelectedSide(1)
                            }} className={location.pathname.includes('/sobre') ? 'sideMenuItem2' : 'sideMenuItem'}><span>Sobre</span><RightChevron /></div>
                            <div onClick={() => {
                                // setSelectedSide(2)
                                setOpen(false)
                                navigate('/urbano')
                            }} className='sideMenuItem'><span>Urbano</span><RightChevron /></div>
                            <div onClick={() => {
                                // setSelectedSide(3)
                                setOpen(false)
                                navigate('/express')
                                // setDialogOpen(true)
                            }} className={location.pathname.includes('admissoes') ? 'sideMenuItem2' : 'sideMenuItem'}><span>Express</span><RightChevron /></div>
                            <div onClick={() => {
                                // setSelectedSide(4)
                                // navigate('/interprovincial')
                                setOpen(false)
                                setDialogOpen(true)

                            }} className={location.pathname.includes('colaboradores') ? 'sideMenuItem2' : 'sideMenuItem'}><span>Interprovincial</span><RightChevron /></div>
                            <div className={location.pathname.includes('sugestoes') ? 'sideMenuItem2' : 'sideMenuItem'} onClick={() => {
                              navigate('/noticias')
                        //         setOpen(false)
                                
                        //         setTimeout(() => {

                        //             navigate('/sugestoes_e_reclamacoes')
                        // localStorage.setItem('path', 'Sugestões/Reclamações');
                        //         }, 500)       
                   }}>
                                Notícias
                            </div>
                            {/* <div onClick={() => {
                                // setOpen(false)
                                // setTimeout(() => {
                                //     navigate('/noticias')
                                // }, 500)
                            }} className={location.pathname.includes('/noticias') ? 'sideMenuItem2' : 'sideMenuItem'}><span>Notícias</span></div> */}
                            <div>
                                
                            </div>
                        </motion.div>
                        : selectedSide == 1 ?
                        <SideMenuItem>
                        <div onClick={() => setSelectedSide(0)} className='sideMenuItem1'><LeftChevron /></div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Quem+Somos')}>Quem Somos</div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Missão+e+Visão')}>Missão e Visão</div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Valores')}>Valores</div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Nossos+serviços')}>Nossos serviços</div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Nossa+frota')}>Nossa frota</div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Compromisso+com+segurança')}>Compromisso</div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Nossos+Diferenciais')}>Nossos diferenciais</div>
                        <div className="sideMenuItem" onClick={() => navigate('/sobre?section=Contactos')}>Contactos</div>
                        {/* <div className='sideMenuItem' onClick={() => {
                        // setOpen(false)

                        // setTimeout(() => {
                        //     axios.get(`${baseURL}/api/admissionsContents`)
                        // .then(res => {
                        //     let content = [...res.data][0]
                        //     if (content.emolumentos) {
                        //         window.open(`${baseURL}/public/storage/pdfs/${content.emolumentos}`)

                        //     }else {
                        //         setDialogOpen(true)
                        //     }
                        // setOpen(false)
                        // })
                        // }, 500)
                        
                        
                    }}><span>Emolumentos/Propinas</span></div> */}
                        
                        
                    </SideMenuItem>:
                        selectedSide == 2 ?
                        <SideMenuItem>
                        <div onClick={() => setSelectedSide(0)} className='sideMenuItem1'><LeftChevron /></div>
                        {destinations.map((item, index) => {
                return (
                  <div className='sideMenuItem' onClick={() => {
                    
                    
                }}>{item?.name}  &#8594; {item?.name}</div>
                )
            })}
                        <div onClick={() => {
                            
                        }} className='sideMenuItem3'><span>Ver mais rotas</span></div>
                        
                    </SideMenuItem>
                        : selectedSide == 3 ? <SideMenuItem>
                        <div onClick={() => setSelectedSide(0)} className='sideMenuItem1'><LeftChevron /></div>

                        {destinations.map((item, index) => {
                return (
                  <div className='sideMenuItem' onClick={() => {
                    
                    
                }}>{item?.name}  &#8594; {item?.name}</div>
                )
            })}
                        <div onClick={() => {
                            
                        }} className='sideMenuItem3'><span>Ver mais rotas</span></div>
                    </SideMenuItem>
                    :
                    <SideMenuItem>
                        <div onClick={() => setSelectedSide(0)} className='sideMenuItem1'><LeftChevron /></div>

                        {destinations.slice(0, 4).map((item, index) => {
                        return (
                        <div className='sideMenuItem05' onClick={() => {


                        }}>
                          <div>{item?.name} </div>
                          <div>
                          {!item?.name.includes('Veja mais') && `${`Veja as vendas de passagens partindo de ${item?.name}, e muito mais...`}`}

                          </div>
                        </div>
                        )
                        })}
                        <div onClick={() => {
                            
                        }} className='sideMenuItem3'><span>Ver mais rotas</span></div>
                    </SideMenuItem>}
                        </ScrollArea>
                        
                    </div>
                </motion.div>
                            </SheetContent>
                        </Sheet>
            </div>
        </div>
    )
}

const ListItem = forwardRef(({ className, title, children, setDialogOpen, ...props}, ref) => {
    return (
      <li className="listItem" onClick={() => {
                    setDialogOpen(true)

      }}>
        <NavigationMenuLink asChild>
          <Link // Use Link from react-router-dom
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {!title.includes('Veja mais') && `${`Veja as vendas de passagens partindo de ${title}, e muito mais...`}`}
            </p>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  });
  const RightChevron = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg>
    )
}
const LeftChevron = () => {
    return (
        <svg style={{cursor: 'pointer', width: 30, height: 30}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
</svg>
    )
}
const SideMenuItem = ({children, ...props}) => {
  
  return (
      <motion.div className='sideMenuItems'
      initial={{
          opacity: 0,
          x: 100,
      }}
      animate={{
          opacity: 1,
          x: 0
      }}
      exit={{
          opacity: 0,
          x: 100
      }}
      >
          {children}
      </motion.div>
  )
}

// Don't forget to give ListItem a display name for debugging
ListItem.displayName = 'ListItem'; 
  
export default Header