import Lottie from "lottie-react"

const { AlertDialog, AlertDialogContent, AlertDialogTitle } = require("../@/components/ui/alert-dialog")

const Loading1 = ({loading}) => {
    return (
        <>
            {
                loading && <AlertDialog open={true}>
                    
                    <AlertDialogContent style={{flexDirection: 'column', display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                    <div style={{display: 'none'}}>
                        <AlertDialogTitle></AlertDialogTitle>
                    </div>
                    <Lottie rendererSettings={
        {
            preserveAspectRatio: 'xMidYMid slice'
        }
        } autoplay loop animationData={require('../components/loading.json')} height={400} width={400}/>
                    </AlertDialogContent>
                </AlertDialog>
            }
        </>

    )
}
export default Loading1