import Marquee from 'react-fast-marquee'
import '../css/testimonials.css'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Testimonials = ({homeContent}) => {
    const [size, setSize] = useState(window.innerWidth)
    const [placeholder, setPlaceholder] = useState([
        {
            id: 0,
            name: 'Luís Almeida',
            date: Date.now(),
            review: 'A AngoTrans é a melhor opção para viagens interprovinciais! O serviço foi pontual e os motoristas são muito atenciosos. Recomendo!',
            via: 'Facebook',
        },
        {
            id: 1,
            name: 'Patrícia Gomes',
            date: Date.now(),
            review: 'Fiz uma viagem urbana com a AngoTrans e fiquei impressionada com a limpeza e o conforto do veículo. Uma experiência muito agradável!',
            via: 'Instagram',
        },
        {
            id: 2,
            name: 'Ricardo Martins',
            date: Date.now(),
            review: 'A AngoTrans me ajudou a chegar a tempo na minha reunião. O aplicativo é fácil de usar e o suporte ao cliente foi excelente!',
            via: 'Facebook',
        },
        {
            id: 3,
            name: 'Sofia Almeida',
            date: Date.now(),
            review: 'Viajar com a AngoTrans é sempre seguro e confortável. O atendimento é nota 10 e os preços são justos.',
            via: 'Instagram',
        },
        {
            id: 4,
            name: 'Mário Fernandes',
            date: Date.now(),
            review: 'Utilizei os serviços da AngoTrans para um evento e foi perfeito. O transporte foi organizado e todos chegaram a tempo!',
            via: 'Facebook',
        },
        {
            id: 5,
            name: 'Cláudia Sousa',
            date: Date.now(),
            review: 'Recomendo a AngoTrans para quem busca um serviço de qualidade no transporte urbano. Motoristas experientes e atenciosos.',
            via: 'Instagram',
        },
    ]);
    return (
        <div className='homeSection' id='testimonials'>
            <div className='description'>O que os nossos</div>
            <div className='title'>Clientes Dizem</div>
            <Marquee speed={50} gradient gradientWidth={size > 500 ? size / 200 * 20 : 50} gradientColor='white' pauseOnClick pauseOnHover style={{
                    display: 'flex',
                    justifyContent: 'stretch',

                    
                }}>
                    {homeContent?.testemunhos?.filter((i)=> i?.status == 'activo').length > 0 ? 
                    homeContent?.testemunhos?.filter((i)=> i?.status == 'activo').slice(0, homeContent?.testemunhos?.filter((i)=> i?.status == 'activo')?.length/2).map((review, index) => {
                        return (
                            <div key={index} class="card" >
                                <svg id='quoteIcon' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-quote" viewBox="0 0 16 16">
  <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
</svg>
                    
                    <div class="cardReview">
                    {review?.info?.testemunho}
                    </div>
                    <div class="cardBottom">
                        <div class="cardInfo">
                            <div class="cardStars">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                            </div>
                            <div class="cardName">{review?.info?.nome}</div>
                            <div class="cardData">{new Date(review?.info?.data).getDate()}/{new Date(review?.info?.data).getMonth()+1}/{new Date(review?.info?.data).getFullYear()} via {review?.info?.via}</div>
                        </div>
                        {review?.info?.via?.toLocaleLowerCase() == 'facebook' ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                        </svg> : 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                      </svg>}
                    </div>
                    
                </div>
                        )
                    })
                    :
                        placeholder.slice(0, 3).map((review, index) => {
                            return (
                                <div class="card" >
                                    <Skeleton style={{background: '#e3e3e3', width: 30, height: 30}} className='quoteIcon'/>

                            <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}}  count={2}/>
                            <div className='cardBottom' style={{alignItems: 'flex-end'}}>
                                <div class="cardInfo">
                                <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}} className=''/>
                                
                                <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}} className='cardName'/>
                                <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}} className='cardData'/>
                                <div style={{opacity: 0, height: 5}} class="cardData">{new Date(review.date).getDate()}/{new Date(review.date).getMonth()+1}/{new Date(review.date).getFullYear()} via {review.via}</div>
                            </div>
                            <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0, width: 30, height: 30, alignSelf: 'flex-end'}} className='cardName'/>

                        </div>
                    
                </div>
                            )
                        })
                    }
                </Marquee>
                {/* switch direction */}
                
                <Marquee direction='right' speed={50} gradient gradientColor='white' gradientWidth={size > 500 ? size / 200 * 20 : 50} pauseOnClick pauseOnHover style={{
                    display: 'flex',
                    
                }}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'stretch', flex: 1, }}>
                    {homeContent?.testemunhos?.filter((i)=> i?.status == 'activo').length > 2 ? 
                    homeContent?.testemunhos?.filter((i)=> i?.status == 'activo')?.slice(homeContent?.testemunhos?.filter((i)=> i?.status == 'activo')?.length/2).map((review, index) => {
                        return (
                            <div key={index} class="card" >
                                <svg id='quoteIcon' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-quote" viewBox="0 0 16 16">
  <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z"/>
</svg>
                    
                    <div class="cardReview">
                    {review?.info?.testemunho}
                    </div>
                    <div class="cardBottom">
                        <div class="cardInfo">
                            <div class="cardStars">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                            </div>
                            <div class="cardName">{review?.info?.nome}</div>
                            <div class="cardData">{new Date(review?.info?.data).getDate()}/{new Date(review?.info?.data).getMonth()+1}/{new Date(review?.info?.data).getFullYear()} via {review?.info?.via}</div>
                        </div>
                        {review?.info?.via?.toLocaleLowerCase() == 'facebook' ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                        </svg> : 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                      </svg>}
                    </div>
                    
                </div>
                        )
                    
                    })
                    :
                    placeholder.slice(0, 3).map((review, index) => {
                            return (
                                <div class="card" >
                                    <Skeleton style={{background: '#e3e3e3', width: 30, height: 30}} className='quoteIcon'/>

                            <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}}  count={2}/>
                            <div className='cardBottom' style={{alignItems: 'flex-end'}}>
                                <div class="cardInfo">
                                <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}} className=''/>
                                
                                <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}} className='cardName'/>
                                <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0}} className='cardData'/>
                                <div style={{opacity: 0, height: 5}} class="cardData">{new Date(review.date).getDate()}/{new Date(review.date).getMonth()+1}/{new Date(review.date).getFullYear()} via {review.via}</div>
                            </div>
                            <Skeleton style={{background: '#e3e3e3', margin: 0, padding: 0, width: 30, height: 30, alignSelf: 'flex-end'}} className='cardName'/>

                        </div>
                    
                </div>
                            )
                        })
                    }
                    </div>
                </Marquee>
        </div>
    )
}
export default Testimonials