import { Edit, HeaderLabel } from "./adminHome"
import '../css/adminFrota.css'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../@/components/ui/dialog"
import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { baseURL, imageURL } from "../api/api"
import { useNavigate } from "react-router-dom"
import getCroppedImg from "./cropImage"
import Cropper from "react-easy-crop"
import ReactQuill from "react-quill"
import { ScrollArea } from "../@/components/ui/scroll-area"
import Lottie from "lottie-react"
import Loading1 from "./loading1"
import ReactSwitch from "react-switch"

const AdminFrota = () => {
    const navigate =useNavigate()
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [videoPreview, setVideoPreview] = useState(null)
    const [load, setLoad] = useState(false)
    const [errors, setErrors] = useState([])
    const [messages, setMessages] = useState([])
    const [title, setTitle] = useState('')
    const [saidas, setSaidas] = useState('')
    const [description, setDescription] = useState('')
    const [departments, setDepartments] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)
    const [crop, setCrop]= useState({x: 0, y: 0})
    const [zoom, setZoom]= useState(1)
    const [frotas, setFrotas] = useState([])
    const [completedCrop, setCompletedCrop] = useState(null)
    const imageRef = useRef(null)    
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [aboutContent, setAboutContent] = useState(null)
    const [show, setShow] = useState(false)
    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
        const croppedImageUrl = await getCroppedImg(selectedImage, croppedAreaPixels)
        setCompletedCrop(croppedImageUrl)

    }
    const onCrop = async () => {
        const croppedImageUrl = await getCroppedImg(selectedImage, croppedAreaPixels)
        setCompletedCrop(croppedImageUrl)
    }
    const addFrota = async () => {
        setLoad(true)
        setErrors([])
        setMessages([])
        if (title.length == 0 || description.length == 0 || !completedCrop){
            setErrors(['Campos em vazio!'])
            setLoad(false)
        }else {
            setLoad(true)
             try {
                const response = await fetch(completedCrop)
                const blob = await response.blob()
                const reader = new FileReader()
                let b64 = ''
                reader.onloadend = () => {
                    b64 = reader.result
                    const regex = /^data:(.+);base64,/;
                    const match = b64.match(regex);
                    const base64Data = b64.replace(match[0], '')
                    
                    axios.post(`${baseURL}/aboutcontents/addFrota/${aboutContent?.id}`, {
                        image_data: base64Data,
                        title: title,
                        description: description,
                        id: [...frotas]?.length + 1
                        
                    })
                    .then(res => {
                        setLoad(false)
                        let content = [...res.data][[...res.data].length - 1]
                        setFrotas(content?.frota||[])
                        setMessages(['Frota adicionada!'])
                        window.location.reload()

                    })
                    .catch(err => {
                        console.log('err', err)
                        setLoad(false)

                        //('error', err.response.data.message)
                    })
                }
                reader.readAsDataURL(blob)
                

            }catch (err) {
                setLoad(false)
                console.error('Error', err)
            }
        }
                    
                    
                
        
    }
    const editFrota = async (item) => {
        setLoad(true)
        setErrors([])
        setMessages([])
        if (title.length == 0 || description.length == 0){
            setErrors(['Campos em vazio!'])
            setLoad(false)
        }else {
            setLoad(true)
            if (completedCrop){
                try {
                    const response = await fetch(completedCrop)
                    const blob = await response.blob()
                    const reader = new FileReader()
                    let b64 = ''
                    reader.onloadend = () => {
                        b64 = reader.result
                        const regex = /^data:(.+);base64,/;
                        const match = b64.match(regex);
                        const base64Data = b64.replace(match[0], '')
                        
                        axios.post(`${baseURL}/aboutcontents/editFrota/${aboutContent?.id}`, {
                            image_data: base64Data,
                            titulo: title,
                            description: description,
                            id: item?.id,
                            status: show ? 'activo' : 'inactivo'
                            
                        })
                        .then(res => {
                            setLoad(false)
                            let content = [...res.data][[...res.data].length - 1]
                            setFrotas(content?.frota||[])
                            setMessages(['Frota adicionada!'])
                            window.location.reload()
    
                        })
                        .catch(err => {
                            console.log('err', err)
                            setLoad(false)
    
                            //('error', err.response.data.message)
                        })
                    }
                    reader.readAsDataURL(blob)
                    
    
                }catch (err) {
                    setLoad(false)
                    console.error('Error', err)
                }
            }else {
                axios.post(`${baseURL}/aboutcontents/editFrota/${aboutContent?.id}`, {
                    titulo: title,
                    description: description,
                    id: item?.id,
                    status: show ? 'activo' : 'inactivo'
                    
                })
                .then(res => {
                    setLoad(false)
                    let content = [...res.data][[...res.data].length - 1]
                    setFrotas(content?.frota||[])
                    setMessages(['Frota adicionada!'])
                    window.location.reload()

                })
                .catch(err => {
                    console.log('err', err)
                    setLoad(false)

                    //('error', err.response.data.message)
                })
            }
        }
                    
                    
                
        
    }
    
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        axios.get(`${baseURL}/aboutcontents`)
        .then(res => {
            let content = [...res.data][[...res.data].length - 1]
            setFrotas(content?.frota||[])
            
            setAboutContent(content)
            setLoading(false)

        })
        .catch(err => {
            setLoading(false)

        })
    }, [])
    return (
        <div className="dashboardContainer" id='adminHomeContainer'>
            <Loading1 loading={loading}/>
            <div className="title">Frota da AngoTrans</div>
            
            <Dialog>
                            <DialogTrigger onClick={() => {
                                setTitle(departments[0]?.info?.titulo)
                                setDescription(departments[0]?.info?.descricao)
                                setSaidas(departments[0]?.info?.saidas)
                                
                                setImagePreview(departments[0]?.info?.imagem)
                                //('info', departments[0]?.info)
                                setSelectedImage(null)
                                setCompletedCrop(null)
                            }}>
<div style={{background: '#F7F7F7', fontSize: 13, width: 'max-content', padding: 10, borderRadius: 5, color: 'black', fontWeight: '500', cursor: 'pointer', border: '1px solid #e2e2e2'}}>Adicionar frota</div>                            </DialogTrigger>
                            <DialogContent style={{width: '100%', maxWidth: 1000, display: 'flex', flexDirection: 'column'}}>
                            <DialogHeader>
                            <DialogTitle>Adicionar</DialogTitle>
                            <DialogDescription>
                                Adicionar frota
                            </DialogDescription>
                            </DialogHeader>
                            
                            <ScrollArea style={{height: '80vh', marginTop: 20}}>
                                <div className='sectionForms'>
                                    <div className="form">
                                        <div
                                        className="label">Tipo de veículo</div>
                                        <input value={title} onChange={(e)=> {
                                            setTitle(e.target.value)
                                        }} placeholder="Ex: Camiões..." className="loginInput"/>
                                    </div>
                                    <div className='form'>
                                            <div className='label'>Imagem do tipo de veiculo</div>
                                            <input className='imageInput1' type='file' accept='image/*'  onChange={(e)=>{
                                        let file = e.target.files[0]
                                        if (file){
                                            const reader = new FileReader()
                                            reader.onload = (event) => {
                                                setSelectedImage(event.target.result)
                                                //('data', event.target.result)
                                            }
                                            reader.readAsDataURL(file)
                                        }else {
                                            setSelectedImage(null)
                                        }
                                    }}/>
                                    {/* <div className='selectedImage'>
                                        <img loading="lazy"src={selectedImage}/>
                                    </div> */}
                                     
                                            {selectedImage && <div style={{height: '100%', aspectRatio: 4/2, position: 'relative'}}>
                                                <Cropper
                                                
                                                style={{height: '100%', width: '100%', position: 'relative'}}
                                                image={selectedImage}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={4/2.2}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                                />
                                            </div>}
                                    
                                    
                                    {(!completedCrop && imagePreview) && <div style={{aspectRatio: '4/2', background: '#d3d3d3', borderRadius: 5, overflow: 'hidden'}}>
                                        <img loading="lazy"style={{width: '100%', height: '100%', objectFit: 'cover'}} src={`${baseURL}/public/storage/images/${imagePreview}`}/>
                                    </div>}
                                        </div>
                                    <div className='form'>
                                        <div className='label'>Descrição</div>
                                        <textarea value={description} onChange={(e)=>{
                                            setDescription(e.target.value)
                                        }} placeholder='Descrição...' className='loginInput' style={{height: 100}}/>
                                    </div>
                                
                                <div className="errors">
                                {errors.length > 0 && errors.map((item, index) => {
                                    return (
                                        <div className="error" key={index}>
                                            {item}
                                        </div>
                                    )
                                })}
                                <div className="errors">
                                {messages.length > 0 && messages.map((item, index) => {
                                    return (
                                        <div style={{color: 'green'}}  className="error" key={index}>
                                            {item}
                                        </div>
                                    )
                                })}
                                                        </div>
                                                        </div>
                                                        {load && <Lottie rendererSettings={
                            {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                            } autoplay loop animationData={require('../components/loading1.json')}style={{width: 100, height: 100}} className='lottieItem'/>}
                                    <div className='buttons' style={{marginBottom: 50}}>
                                        <div onClick={() => {
                                            addFrota()
                                        }} className='save'>Adicionar</div>
                                    </div>
                                    {/* <div className='' dangerouslySetInnerHTML={{__html: prMessage}}>
                                    </div> */}
                                
                                
                                </div>
                            </ScrollArea>
                            </DialogContent>
                        </Dialog>
            <div style={{display: 'grid'}} className="first" id='first1'>
                {frotas.length > 0 ? [...frotas].map((item, index) => {
                    return (
                        <section className="section">
                    <div className="header">
                        <HeaderLabel title={item?.titulo}/>
                        <Dialog>
                            <DialogTrigger onClick={() => {
                                setTitle(item?.titulo||'')
                                setDescription(item?.description)
                                
                                setImagePreview(item?.imagem)
                                //('info', departments[0]?.info)
                                item?.status == 'activo' ? setShow(true) : item?.status == 'inactivo' && setShow(false)

                                setSelectedImage(null)
                                setCompletedCrop(null)
                            }}>
                                <Edit title='Editar'/>
                            </DialogTrigger>
                            <DialogContent style={{width: '100%', maxWidth: 1000, display: 'flex', flexDirection: 'column'}}>
                            <DialogHeader>
                            <DialogTitle>Editar</DialogTitle>
                            <DialogDescription>
                                Editar frota
                            </DialogDescription>
                            </DialogHeader>
                            
                            <ScrollArea style={{height: '80vh', marginTop: 20}}>
                                <div className='sectionForms'>
                                    <div className="form">
                                        <div
                                        className="label">Tipo de veículo</div>
                                        <input value={title} onChange={(e)=> {
                                            setTitle(e.target.value)
                                        }} placeholder="Ex: Camiões..." className="loginInput"/>
                                    </div>
                                    <div className='form'>
                                            <div className='label'>Imagem do tipo de veiculo</div>
                                            <input className='imageInput1' type='file' accept='image/*'  onChange={(e)=>{
                                        let file = e.target.files[0]
                                        if (file){
                                            const reader = new FileReader()
                                            reader.onload = (event) => {
                                                setSelectedImage(event.target.result)
                                                //('data', event.target.result)
                                            }
                                            reader.readAsDataURL(file)
                                        }else {
                                            setSelectedImage(null)
                                        }
                                    }}/>
                                    {/* <div className='selectedImage'>
                                        <img loading="lazy"src={selectedImage}/>
                                    </div> */}
                                     {selectedImage && <div style={{color: 'red', fontSize: 14, cursor: 'pointer', fontWeight: 600}} onClick={() => {
                                                setSelectedImage(null)
                                                setCompletedCrop(null)
                                            }}>Remover imagem</div>}
                                            {selectedImage && <div style={{height: '100%', aspectRatio: 4/2, position: 'relative'}}>
                                                <Cropper
                                                
                                                style={{height: '100%', width: '100%', position: 'relative'}}
                                                image={selectedImage}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={4/2.2}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                                />
                                            </div>}
                                    
                                    
                                    {(!completedCrop && imagePreview) && <div style={{aspectRatio: '4/2', background: '#d3d3d3', borderRadius: 5, overflow: 'hidden'}}>
                                        <img loading="lazy"style={{width: '100%', height: '100%', objectFit: 'cover'}} src={`${imageURL}/${imagePreview}`}/>
                                    </div>}
                                        </div>
                                    <div className='form'>
                                        <div className='label'>Descrição</div>
                                        <textarea value={description} onChange={(e)=>{
                                            setDescription(e.target.value)
                                        }} placeholder='Descrição...' className='loginInput' style={{height: 100}}/>
                                    </div>
                                <div className='form'>
                                    <ReactSwitch uncheckedIcon={null} checkedIcon={null} checked={show} onChange={() => {
                                        setShow(!show)
                                    }} />
                                </div>
                                <div className="errors">
                                {errors.length > 0 && errors.map((item, index) => {
                                    return (
                                        <div className="error" key={index}>
                                            {item}
                                        </div>
                                    )
                                })}
                                <div className="errors">
                                {messages.length > 0 && messages.map((item, index) => {
                                    return (
                                        <div style={{color: 'green'}}  className="error" key={index}>
                                            {item}
                                        </div>
                                    )
                                })}
                                                        </div>
                                                        </div>
                                                        {load && <Lottie rendererSettings={
                            {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                            } autoplay loop animationData={require('../components/loading1.json')}style={{width: 100, height: 100}} className='lottieItem'/>}
                                    <div className='buttons' style={{marginBottom: 50}}>
                                        <div onClick={() => {
                                            editFrota(item)
                                        }} className='save'>Adicionar</div>
                                    </div>
                                    {/* <div className='' dangerouslySetInnerHTML={{__html: prMessage}}>
                                    </div> */}
                                
                                
                                </div>
                            </ScrollArea>
                            </DialogContent>
                        </Dialog>
                    </div>
                    
                    <div className="info">
                        <div className="basicInfo">{item?.description}
                            
                        </div>
                        <div className="videoCont">
                            {<img loading="lazy"  style={{width: '100%', height: '100%', objectFit: 'cover'}} src={`${imageURL}/${item?.imagem}`} loop muted controls />}
                        </div>
                    </div>
                </section>
                    )
                }): 
                <div style={{fontSize: 14, textAlign: 'left'}}>Nenhuma frota adicionada ainda. Adicione uma.</div>}
                
            </div>
        </div>
    )
}
export default AdminFrota