import { useState } from 'react'
import '../css/recommendations.css'
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '../@/components/ui/dialog'
import { ScrollArea } from '../@/components/ui/scroll-area'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
const Recommendations = () => {
    const navigate = useNavigate()
    const [destinations, setDestinations] = useState([
        { name: "Luanda", capital: "Luanda", image: 'https://picsum.photos/id/277/1280/900.webp' },
    { name: "Benguela", capital: "Benguela", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Huambo", capital: "Huambo", image: 'https://picsum.photos/id/66/1280/900.webp' },
    { name: "Huíla", capital: "Lubango", image: 'https://picsum.photos/id/350/1280/900.webp' },
    { name: "Namibe", capital: "Moçâmedes", image: 'https://picsum.photos/id/221/1280/900.webp' },
    { name: "Kwanza Norte", capital: "N'dalatando", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Kwanza Sul", capital: "Sumbe", image: 'https://picsum.photos/id/313/1280/900.webp' },
    { name: "Malanje", capital: "Malanje", image: 'https://picsum.photos/id/15/1280/900.webp' },
    { name: "Lunda Norte", capital: "Lunda", image: 'https://picsum.photos/id/168/1280/900.webp' },
    { name: "Lunda Sul", capital: "Saurimo", image: 'https://picsum.photos/id/368/1280/900.webp' },
    { name: "Moxico", capital: "Luena", image: 'https://picsum.photos/id/542/1280/900.webp' },
    { name: "Cunene", capital: "Ondjiva", image: 'https://picsum.photos/id/851/1280/900.webp' },
    // { name: "Bengo", capital: "Caxito" },
    // { name: "Cabinda", capital: "Cabinda" },
    // { name: "Zaire", capital: "Mbanza Congo" },
    // { name: "Uíge", capital: "Uíge" },
    // { name: "Cuando Cubango", capital: "Menongue" }
    ])
    const [selected, setSelected] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)

    return (
        <div className='homeSection' id='recommendations' style={{maxWidth: 1700}}>
            <Dialog open={dialogOpen} onOpenChange={(e) => {
                setDialogOpen(e)
            }} >
                <DialogTrigger style={{display: 'none'}}>

                </DialogTrigger>
                <DialogContent style={{width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column',}}>
                <Lottie rendererSettings={
                    {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                    } autoplay loop animationData={require('../components/loading6.json')}style={{width: 250, height: 250, alignSelf: 'center', marginTop: 40}} className='lottieItem'/>

                    <div style={{textAlign: 'center', fontSize: 20, color: '#495057', fontWeight: '500', marginTop: 30}}>Página em manutenção. Ele estará disponivel em breve</div>
                    <div style={{textAlign: 'center', color: 'grey', fontSize: 14}}>Esta página estará disponivel em breve. Por enquanto subsreva-se ao nosso boletim informativo para ser notificado assim que o aplicativo for lançado.</div>

                    <div style={{paddingTop: 20, width: '100%', borderTop: '2px solid #e6e6e6'}}></div>

                </DialogContent>
            </Dialog>
            <div className='description' >Destinos</div>
            <div className='title' >Populares</div>

            
            <div className='destinations'>
                {destinations.slice(0, 8).map((item) => {
                    return (
                        <div className='destination'>
                            <div className='imageContainer'>
                                <img src={item?.image}/>
                            </div>
                            <div className='info'>
                                <div className='destTitle'>De: {item?.capital}</div>
                                <div className='destDescription'>Para: Malanje</div>
                                <div className='locationDuration'>
                                        <div className='ini'>
                                            12:00
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg>
                                        <div className='duration'>
                                            10 horas
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg>
                                        <div className='final'>
                                            22:00
                                        </div>
                                    </div>
                                <div className='priceRow'>
                                <div className='price'>15.000 Kz</div>
                                <div className='button' onClick={() => {
                                            // setSelected(item)
                                            setDialogOpen(true)
                                            // navigate('/interprovincial', {
                                            //     state: {
                                            //        info: item 
                                            //     }
                                            // })
                                        }}>
                                    Ver mais horários
                                </div>
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default Recommendations