import { useEffect, useState } from 'react'
import Header from '../components/header'
import '../css/interpro.css'
import '../css/urbano.css'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../@/components/ui/select'
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '../@/components/ui/dialog'
import { format } from 'date-fns'
import { ScrollArea } from '../@/components/ui/scroll-area'
import Footer from '../components/footer'
import Download from '../components/download'
import axios from 'axios'
import { baseURL } from '../api/api'
import Skeleton from 'react-loading-skeleton'
import Lottie from 'lottie-react'
const Express = () => {
    const [tag, setTag] = useState('Somente Ida')
    const angolaProvinces = [
        { name: "Luanda", capital: "Luanda" },
        { name: "Benguela", capital: "Benguela" },
        { name: "Huambo", capital: "Huambo" },
        { name: "Huíla", capital: "Lubango" },
        { name: "Namibe", capital: "Moçâmedes" },
        { name: "Kwanza Norte", capital: "N'dalatando" },
        { name: "Kwanza Sul", capital: "Sumbe" },
        { name: "Malanje", capital: "Malanje" },
        { name: "Lunda Norte", capital: "Lunda" },
        { name: "Lunda Sul", capital: "Saurimo" },
        { name: "Moxico", capital: "Luena" },
        { name: "Cunene", capital: "Ondjiva" },
        { name: "Bengo", capital: "Caxito" },
        { name: "Cabinda", capital: "Cabinda" },
        { name: "Zaire", capital: "Mbanza Congo" },
        { name: "Uíge", capital: "Uíge" },
        { name: "Cuando Cubango", capital: "Menongue" }
    ];
    const [destinations, setDestinations] = useState([
        { name: "Rotunda Gamek", capital: "Mercado São Paulo", image: 'https://picsum.photos/id/277/1280/900.webp' },
    { name: "Vila do Gamek", capital: "Largo 4 de Fevereiro", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Control Golf 2", capital: "Vila do Gamek - Pedalê", image: 'https://picsum.photos/id/66/1280/900.webp' },
    { name: "Control Golf 2", capital: "Benfica", image: 'https://picsum.photos/id/350/1280/900.webp' },
    { name: "Vila Estoril", capital: "Cuca", image: 'https://picsum.photos/id/221/1280/900.webp' },
    { name: "Benfica", capital: "Shoprite", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Benfica", capital: "1º de Maio", image: 'https://picsum.photos/id/313/1280/900.webp' },
    { name: "Benfica", capital: "Mutamba", image: 'https://picsum.photos/id/15/1280/900.webp' },
    { name: "Correios", capital: "Mutamba", image: 'https://picsum.photos/id/168/1280/900.webp' },
   
    // { name: "Bengo", capital: "Caxito" },
    // { name: "Cabinda", capital: "Cabinda" },
    // { name: "Zaire", capital: "Mbanza Congo" },
    // { name: "Uíge", capital: "Uíge" },
    // { name: "Cuando Cubango", capital: "Menongue" }
    ])
    const [selected, setSelected] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [rotas, setRotas] = useState([])
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(null)
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(`${baseURL}/rotasExpress`)
        .then(res => {
            console.log('res', res.data)
            setRotas([...res.data])
        })
    }, [])
    return (
        <div>
            <Dialog open={open} onOpenChange={(e) => {
                setOpen(e)
            }} >
                <DialogTrigger>

                </DialogTrigger>
                <DialogContent style={{width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column',}}>
                {/* <Lottie rendererSettings={
                    {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                    } autoplay loop animationData={require('../components/loading6.json')}style={{width: 250, height: 250, alignSelf: 'center', marginTop: 40}} className='lottieItem'/> */}

                    <div style={{textAlign: 'center', fontSize: 20, color: '#495057', fontWeight: '500', marginTop: 30}}>Detalhes da rota</div>
                    <div className='locationDuration' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', gap: 10}}>
                                        <div className='ini' style={{fontSize: 13, fontWeight: '500'}}>
                                            {item?.partida}
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
        </svg>
        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
        </svg>
                                        <div className='final'style={{fontSize: 13, fontWeight: '500'}}>
                                            {item?.chegada}
                                        </div>
                                    </div>                    <div className='info'>
                        <label style={{fontSize: 13, fontWeight: '500', color: 'whtie'}}>Rotas</label>
                        <div className='ul' style={{fontSize: 14, marginTop: 10}} dangerouslySetInnerHTML={{__html: item?.horarios}}></div>
                        
                    </div>
                    {item?.link_rota && <div className='valueButton' style={{width: 150}} onClick={() => {
                        window.open(`${item?.link_rota}`)
                    }}>Ver a rota num mapa</div>}
                    <div style={{paddingTop: 20, width: '100%', borderTop: '2px solid #e6e6e6'}}></div>

                </DialogContent>
            </Dialog>
            <div id='interpro' style={{marginBottom: 80}}>
            
                <Header />
            
                <div className='interContainer'>
                    <div className='title' style={{marginTop: 70}}>Transporte express
                    </div>
                    <div className='description' style={{color: 'grey'}}>Desenvolvido e operado na província de Luanda, atende atualmente a 9 linhas concedidas pelo Governo Provincial, cumprindo diariamente o horário das 05h:30 às 21h:30, com frota de pelo menos até 20 autocarros.
                    
            </div>
                </div>
                <div className='interContainer'>
                    <div className='left'>
                        <section className='filters'>
                            <div className='filterSection'>
                            </div>
                        </section>
                    </div>
                    <div className='right'>
                        {rotas.length > 0 ? <>
                            <div className='list'>
                                {rotas.map((info) => {
                                    return (
                                        <div className='horario'>
                                            <div>
                                                <div className='horarioTitle'>{info?.partida} &#8594; {info?.chegada}</div>
                                                {/* <div className='angoDuration'>12 horas de viagem (sem paragens)</div> */}
                                            </div>
                                            <div className='arrivals'>
                                                <div className='arrival'>
                                                    <span>Começo</span>
                                                    <div className='arrivalTime'>5:30</div>
                                                    {/* <div className='arrivalDate'>12/8/2024</div> */}
                                                </div>
                                                <div className='arrows'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                </svg>
                                                </div>
    <div className='angotrans'>
        <img src={process.env.PUBLIC_URL + '/images/logo.png'}/>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bus-front-fill" viewBox="0 0 16 16">
            <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"/>
        </svg>
        {/* 12 horas (sem paragens) */}
    </div>
    <div className='arrows'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                </svg>
                                                </div>
                                                <div className='arrival'>
                                                    <span>Término</span>
                                                    <div className='arrivalTime'>21:30</div>
                                                    {/* <div className='arrivalDate'>12/8/2024</div> */}
                                                </div>
                                            </div>
                                            <div className='horarioPrices'>
                                                <div className='horarioPrice'>
                                                    <span>Preço: {item?.valor}</span>
                                                </div>
                                                {/* <div className='bookButton' onClick={() => {
                                                    setSelected(info)
                                                    setDialogOpen(true)
                                                }}>
                                                    Seguir
                                                </div> */}
                                            </div>
                                        </div>
                                    )
                                })}
                                    </div>
                        </>:
                        <div className='list'>
                        {[...destinations].map((item, index) => {
                            let random = Math.floor(Math.random(0, destinations.length - 1))
                            return (
                                <Skeleton className='horario' style={{height: 150}}>
                                    <div className='' style={{background: 'white', position: 'absolute', zIndex: 5, top: 10, left: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, padding: 5,
                                    borderRadius: 10
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bus-front-fill" viewBox="0 0 16 16">
          <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"/>
        </svg> <span style={{fontWeight: 500}}>C0{Math.ceil(index*1.2 * 3 /2)}</span>
                                    </div>
                                <div className='imageContainer'>
                                    <img style={{filter: 'brightness(0.5)'}} src={process.env.PUBLIC_URL + '/images/bus2.jpeg'}/>
                                </div>
                                <div className='info'>
        
                                    <div className='locationDuration'>
                                        <div className='ini'>
                                            {item?.name}
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
        </svg>
        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
        </svg>
                                        <div className='final'>
                                            {item?.capital}
                                        </div>
                                    </div>
        
                                </div>
                            </Skeleton>
                            )
                        })}
                    </div>}
                    </div>
                </div>
            </div>
            <Download />
            <Footer />
        </div>

    )
}
export default Express