import { useEffect, useRef, useState } from 'react'
import '../css/homeSlide.css'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../@/components/ui/select'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAnimationControls, motion } from 'framer-motion'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { imageURL } from '../api/api'
import { Dialog, DialogContent, DialogTrigger } from '../@/components/ui/dialog'
import Lottie from 'lottie-react'
const HomeSlide = ({homeContent}) => {
    const [tag, setTag] = useState('Somente ida')
    const [iniDate, setIniDate] = useState(new Date())
    const [finalDate, setFinalDate] = useState(new Date())
    const navigate = useNavigate()

    const angolaProvinces = [
        { name: "Luanda", capital: "Luanda" },
        { name: "Benguela", capital: "Benguela" },
        { name: "Huambo", capital: "Huambo" },
        { name: "Huíla", capital: "Lubango" },
        { name: "Namibe", capital: "Moçâmedes" },
        { name: "Kwanza Norte", capital: "N'dalatando" },
        { name: "Kwanza Sul", capital: "Sumbe" },
        { name: "Malanje", capital: "Malanje" },
        { name: "Lunda Norte", capital: "Lunda" },
        { name: "Lunda Sul", capital: "Saurimo" },
        { name: "Moxico", capital: "Luena" },
        { name: "Cunene", capital: "Ondjiva" },
        { name: "Bengo", capital: "Caxito" },
        { name: "Cabinda", capital: "Cabinda" },
        { name: "Zaire", capital: "Mbanza Congo" },
        { name: "Uíge", capital: "Uíge" },
        { name: "Cuando Cubango", capital: "Menongue" }
    ];
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0)
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    
    const icon = useRef(null)
    const [open, setOpen] = useState(false)
    const containerControls = useAnimationControls()
    useEffect(() => {
        if (open) {
            containerControls.start('open')
        }else {
            containerControls.start('close')
        }
    }, [open, homeContent])
    const menu = useRef(null)
    const swiperRef = useRef(null)
    const handlePrev = () => {
        if (swiperRef.current) swiperRef.current.slidePrev()
    }
    const handleNext = () => {
        if (swiperRef.current) swiperRef.current.slideNext()
    }
    const [slides, setSlides] = useState([])
    
    useEffect(() => {
        const interval = setInterval(() => {
            //('index', activeIndex)
            if (activeIndex == 2){
                setActiveIndex(0)
            }else {
                setActiveIndex(activeIndex + 1)
            }

        }, 6000);

        return () => clearInterval(interval);
  
    }, [activeIndex, homeContent])
    const secondContainerRef = useRef(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    useEffect(() => {
      const adjustMargin = () => {
        if (secondContainerRef.current) {
            const outerContainerHeight = document.querySelector('.outerContainer')?.clientHeight || 0;
            secondContainerRef.current.style.marginTop = `${outerContainerHeight}px`;
        }
    };
    const intervalId = setInterval(() => {
        adjustMargin(); // Call the function to adjust margin at regular intervals
    }, 1); // Adjust the interval time as needed

    // Cleanup the interval on component unmount
    return () => {
        clearInterval(intervalId);
    };
    }, [homeContent])
    useEffect(() => {
        console.log('homecontent', homeContent?.slides)
    }, [homeContent])
    return (
        <div className="homeBanner" style={{maxWidth: '100%'}}>
            <Dialog open={dialogOpen} onOpenChange={(e) => {
                setDialogOpen(e)
            }} >
                <DialogTrigger style={{display: 'none'}}>

                </DialogTrigger>
                <DialogContent style={{width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column',}}>
                <Lottie rendererSettings={
                    {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                    } autoplay loop animationData={require('../components/loading6.json')}style={{width: 250, height: 250, alignSelf: 'center', marginTop: 40}} className='lottieItem'/>

                    <div style={{textAlign: 'center', fontSize: 20, color: '#495057', fontWeight: '500', marginTop: 30}}>Página em manutenção. Ele estará disponivel em breve</div>
                    <div style={{textAlign: 'center', color: 'grey', fontSize: 14}}>Esta página estará disponivel em breve. Por enquanto subsreva-se ao nosso boletim informativo para ser notificado assim que o aplicativo for lançado.</div>

                    <div style={{paddingTop: 20, width: '100%', borderTop: '2px solid #e6e6e6'}}></div>

                </DialogContent>
            </Dialog>
{homeContent?.slides?.length > 0 ? <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
            delay: 5500,
            disableOnInteraction: false,
        }}
        loop
        slidesPerView={1}
        onChange={() => {
            //('changed')
        }}
        onSlideChange={(e) => {
            // //('changeddddd', e.realIndex)
            setActiveIndex(e.realIndex)
        }}
        
        pagination={{
            clickable: true,
            
        }}
        onSwiper={(e) => {
            swiperRef.current = e
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="swiper"
        >
        {homeContent?.slides?.filter((i)=> i?.status == 'activo')?.length > 0 &&
            homeContent?.slides?.filter((i)=> i?.status == 'activo')?.map((item, index) => {
                return (
                    <SwiperSlide>
                        <div className='slideTextCont'>{activeIndex == index && <motion.div initial={{opacity: 0, x: 200}} animate={{opacity: 1, x: 0}} transition={{duration: 0.8}} className='slideText'>{item?.message}</motion.div>}</div>

                        <img loading="lazy" src={`${imageURL}/${item?.imagem}`} />
                    </SwiperSlide>
                )
            })
        }
        <div onClick={() => handlePrev()} className='button left'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
    </svg></div>
        <div onClick={() => handleNext()} className='button right'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
    </svg></div>
        <div className="autoplay-progress" slot="container-end">
            <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
            </svg>
            <span ref={progressContent}></span>
        </div>
        </Swiper>
        :
        <div className='swiper'></div>}

            {/* <img className='background' src={process.env.PUBLIC_URL + '/images/bus1.jpeg'}/> */}
            
            <div className='form'>
                <div className='tags'>
                    <div onClick={() => {
                        setTag('Somente Ida')
                    }} className={tag.includes('Somente') ? 'tag': 'tag1'}>Somente Ida</div>                    
                    <div onClick={() => {
                        setTag('Ida e volta')
                    }} className={tag.includes('volta') ? 'tag': 'tag1'}>Ida e volta</div>

                </div>
                <div style={{ borderRadius: 10}} className='inputs'>
                <Select className='select' onValueChange={(e) => {
        }}>
    <SelectTrigger className='select'>
        <SelectValue placeholder="Seleccione uma origem" />
    </SelectTrigger>
    <SelectContent>
        {angolaProvinces.map((item, index) => {
            return (
                <SelectItem value={item?.name}>{item?.name}</SelectItem>
            )
        } )}
    </SelectContent>
    </Select>

    <Select className='select' onValueChange={(e) => {
        }}>
    <SelectTrigger className='select'>
        <SelectValue placeholder="Seleccione um destino" />
    </SelectTrigger>
    <SelectContent>
        {angolaProvinces.map((item, index) => {
            return (
                <SelectItem value={item?.name}>{item?.name}</SelectItem>
            )
        } )}
    </SelectContent>
    </Select>
    <input className='loginInput' type='date' placeholder='do'/>
    {tag.includes('volta') && <input className='loginInput' type='date'/>
    }

    <div style={{padding: 8, fontWeight: '500', margin: 0, height: '100%', borderRadius: 8}} className='valueButton' onClick={() => {
        // navigate('/interprovincial')
        setDialogOpen(true)
    }}>
        Procurar
    </div>
                </div>
            </div>
        </div>

    )
}
export default HomeSlide