import { useRef, useState } from 'react'
import '../css/download.css'
import { Dialog, DialogContent, DialogTrigger } from '../@/components/ui/dialog'
import Lottie from 'lottie-react'

const Download = () => {
    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const whats = useRef(null)
    const [open, setOpen] = useState(false)
    return (
        <div className='homeSection' id='downloadContainer' style={{maxWidth: 1700}}>
            <Dialog open={open} onOpenChange={(e) => {
                setOpen(e)
            }} >
                <DialogTrigger>

                </DialogTrigger>
                <DialogContent style={{width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column',}}>
                <Lottie rendererSettings={
                    {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                    } autoplay loop animationData={require('../components/loading6.json')}style={{width: 250, height: 250, alignSelf: 'center', marginTop: 40}} className='lottieItem'/>

                    <div style={{textAlign: 'center', fontSize: 20, color: '#495057', fontWeight: '500', marginTop: 30}}>Aplicativo disponivel em breve</div>
                    <div style={{textAlign: 'center', color: 'grey', fontSize: 14}}>O aplicativo AngoTrans estará disponivel em breve. Por enquanto subsreva-se ao nosso boletim informativo para ser notificado assim que o aplicativo for lançado.</div>

                    <div style={{paddingTop: 20, width: '100%', borderTop: '2px solid #e6e6e6'}}></div>

                </DialogContent>
            </Dialog>
            <div className='left' style={{flex: 1}}>
                <div className='title'>Baixe o nosso aplicativo</div>
                <div className='description'>O <span style={{color: '#0389B0', fontWeight: '700'}}>Ango</span><span style={{color: '#9D090C', fontWeight: '700'}}>Trans</span> Mobile é um aplicativo que rastreia ônibus em tempo real, permite a compra de bilhetes e notifica os usuários sobre a proximidade dos veículos via SMS ou notificações do app. Com uma interface intuitiva, torna a mobilidade urbana mais eficiente e prática.</div>
                <div className='links'>
                    {/* <img src={process.env.PUBLIC_URL + '/images/qr.png'}/> */}
                    <div className='platforms'>
                        <section className='platformContainer'>
                            <div className='platformIcon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-apple" viewBox="0 0 16 16">
                              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                            </svg>
                            </div>
                            <div className='platformDescription'>Dispositivos Mobile</div>
                            <div className='platformID'>IOS</div>
                            <div className='platformDescription1'>Comece já a sua jornada com o AngoTrans</div>
                            <section className='platform' onClick={() => {
                                setOpen(true)
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-apple" viewBox="0 0 16 16">
                              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                            </svg>
                                <div className='info'>
                                    <div className='description'>Baixe no</div>
                                    <div className='title'>App Store</div>
                                </div>
                            </section>
                        </section>
                        <section className='platformContainer'>
                        <div className='platformIcon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-android2" viewBox="0 0 16 16">
  <path d="m10.213 1.471.691-1.26q.069-.124-.048-.192-.128-.057-.195.058l-.7 1.27A4.8 4.8 0 0 0 8.005.941q-1.032 0-1.956.404l-.7-1.27Q5.281-.037 5.154.02q-.117.069-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.7 3.7 0 0 0 3.5 5.02h9q0-1.125-.623-2.072a4.27 4.27 0 0 0-1.664-1.476ZM6.22 3.303a.37.37 0 0 1-.267.11.35.35 0 0 1-.263-.11.37.37 0 0 1-.107-.264.37.37 0 0 1 .107-.265.35.35 0 0 1 .263-.11q.155 0 .267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264m4.101 0a.35.35 0 0 1-.262.11.37.37 0 0 1-.268-.11.36.36 0 0 1-.112-.264q0-.154.112-.265a.37.37 0 0 1 .268-.11q.155 0 .262.11a.37.37 0 0 1 .107.265q0 .153-.107.264M3.5 11.77q0 .441.311.75.311.306.76.307h.758l.01 2.182q0 .414.292.703a.96.96 0 0 0 .7.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182q0 .414.292.703a.97.97 0 0 0 .71.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76q.436 0 .749-.308.31-.307.311-.75V5.365h-9zm10.495-6.587a.98.98 0 0 0-.702.278.9.9 0 0 0-.293.685v4.063q0 .406.293.69a.97.97 0 0 0 .702.284q.42 0 .712-.284a.92.92 0 0 0 .293-.69V6.146a.9.9 0 0 0-.293-.685 1 1 0 0 0-.712-.278m-12.702.283a1 1 0 0 1 .712-.283q.41 0 .702.283a.9.9 0 0 1 .293.68v4.063a.93.93 0 0 1-.288.69.97.97 0 0 1-.707.284 1 1 0 0 1-.712-.284.92.92 0 0 1-.293-.69V6.146q0-.396.293-.68"/>
                        </svg>
                            </div>
                            <div className='platformDescription'>Dispositivos Mobile</div>
                            <div className='platformID'>Android</div>
                            <div className='platformDescription1'>Comece já a sua jornada com o AngoTrans</div>
                            <section className='platform' onClick={() => {
                                setOpen(true)
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google-play" viewBox="0 0 16 16">
                              <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27"/>
                            </svg>
                                <div className='info'>
                                    <div className='description'>Baixe np</div>
                                    <div className='title'>Google Play</div>
                                </div>
                            </section>
                        </section>
                    </div>
                    <img className='hiddenImage' src={process.env.PUBLIC_URL + '/images/deviceframes.png'}/>

                </div>
            </div>
            <div className='right' style={{flex: 1}}>
                <img src={process.env.PUBLIC_URL + '/images/deviceframes.png'}/>
            </div>
        </div>
    )
}
export default Download