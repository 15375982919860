import { useNavigate } from 'react-router-dom'
import '../css/services.css'
import { useState } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../@/components/ui/dialog'
import Lottie from 'lottie-react'
const Services = () => {
    const navigate = useNavigate()
    const [dialogOpen, setDialogOpen] = useState(false)
    return (
        <div className='homeSection' id='services'>
            <Dialog open={dialogOpen} onOpenChange={(e) => {
                setDialogOpen(e)
            }} >
                <DialogTrigger style={{display: 'none'}}>

                </DialogTrigger>
                <DialogContent style={{width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column',}}>
                <Lottie rendererSettings={
                    {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                    } autoplay loop animationData={require('../components/loading6.json')}style={{width: 250, height: 250, alignSelf: 'center', marginTop: 40}} className='lottieItem'/>

                    <div style={{textAlign: 'center', fontSize: 20, color: '#495057', fontWeight: '500', marginTop: 30}}>Página em manutenção. Ele estará disponivel em breve</div>
                    <div style={{textAlign: 'center', color: 'grey', fontSize: 14}}>Esta página estará disponivel em breve. Por enquanto subsreva-se ao nosso boletim informativo para ser notificado assim que o aplicativo for lançado.</div>

                    <div style={{paddingTop: 20, width: '100%', borderTop: '2px solid #e6e6e6'}}></div>

                </DialogContent>
            </Dialog>
            <div className='description' >Nosos</div>
            <div className='title' >Serviços</div>

            <div className='cards'>
            <div className='card'>
                    <div className='cardTitle'>Ubano</div>
                    <div className='cardDescription'>Longas viagens</div>
                    <div className='secondImageContainer'>
                        <img src={process.env.PUBLIC_URL + '/images/urban2.webp'}/>

                    </div>

                    <div className='seeMore' onClick={() => {
                        navigate('/urbano')
                    }}>Ver mais &#8594;

</div>
                </div>
                <div className='card'>
                    <div className='cardTitle'>Express</div>
                    <div className='cardDescription'>Longas viagens</div>
                    <div className='secondImageContainer'>
                        <img style={{transform: 'translateY(27px)', zIndex: 1}} src={process.env.PUBLIC_URL + '/images/express.webp'}/>

                    </div>

                    <div className='seeMore' onClick={() => {
                        setDialogOpen(true)
                    }} style={{
                        cursor: 'pointer',
                        zIndex: 2
                    }} >Ver mais &#8594;

</div>
                </div>
                <div className='card'>
                    <div className='cardTitle'>Inter-provincial</div>
                    <div className='cardDescription'>Longas viagens</div>
                    <div className='secondImageContainer'>
                        <img style={{transform: 'scaleX(-1)'}} src={process.env.PUBLIC_URL + '/images/interpro.webp'}/>

                    </div>

                    <div className='seeMore' onClick={() => {
                        // navigate('./interprovincial')
                        setDialogOpen(true)
                    }} style={{
                        cursor: 'pointer'
                    }} >Ver mais &#8594;

</div>
                </div>
                
            </div>
        </div>
    )
}
export default Services