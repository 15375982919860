import { useEffect, useRef, useState } from 'react'
import Header from '../components/header'
import '../css/interpro.css'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../@/components/ui/select'
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '../@/components/ui/dialog'
import { format } from 'date-fns'
import { ScrollArea } from '../@/components/ui/scroll-area'
import Footer from '../components/footer'
import Download from '../components/download'
import { useLocation } from 'react-router-dom'
const InterPro = () => {
    const [tag, setTag] = useState('Somente Ida')
    const angolaProvinces = [
        { name: "Luanda", capital: "Luanda" },
        { name: "Benguela", capital: "Benguela" },
        { name: "Huambo", capital: "Huambo" },
        { name: "Huíla", capital: "Lubango" },
        { name: "Namibe", capital: "Moçâmedes" },
        { name: "Kwanza Norte", capital: "N'dalatando" },
        { name: "Kwanza Sul", capital: "Sumbe" },
        { name: "Malanje", capital: "Malanje" },
        { name: "Lunda Norte", capital: "Lunda" },
        { name: "Lunda Sul", capital: "Saurimo" },
        { name: "Moxico", capital: "Luena" },
        { name: "Cunene", capital: "Ondjiva" },
        { name: "Bengo", capital: "Caxito" },
        { name: "Cabinda", capital: "Cabinda" },
        { name: "Zaire", capital: "Mbanza Congo" },
        { name: "Uíge", capital: "Uíge" },
        { name: "Cuando Cubango", capital: "Menongue" }
    ];
    const [destinations, setDestinations] = useState([
        { name: "Luanda", capital: "Luanda", image: 'https://picsum.photos/id/277/1280/900.webp' },
    { name: "Benguela", capital: "Benguela", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Huambo", capital: "Huambo", image: 'https://picsum.photos/id/66/1280/900.webp' },
    { name: "Huíla", capital: "Lubango", image: 'https://picsum.photos/id/350/1280/900.webp' },
    { name: "Namibe", capital: "Moçâmedes", image: 'https://picsum.photos/id/221/1280/900.webp' },
    { name: "Kwanza Norte", capital: "N'dalatando", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Kwanza Sul", capital: "Sumbe", image: 'https://picsum.photos/id/313/1280/900.webp' },
    { name: "Malanje", capital: "Malanje", image: 'https://picsum.photos/id/15/1280/900.webp' },
    { name: "Lunda Norte", capital: "Lunda", image: 'https://picsum.photos/id/168/1280/900.webp' },
    { name: "Lunda Sul", capital: "Saurimo", image: 'https://picsum.photos/id/368/1280/900.webp' },
    { name: "Moxico", capital: "Luena", image: 'https://picsum.photos/id/542/1280/900.webp' },
    { name: "Cunene", capital: "Ondjiva", image: 'https://picsum.photos/id/851/1280/900.webp' },
    // { name: "Bengo", capital: "Caxito" },
    // { name: "Cabinda", capital: "Cabinda" },
    // { name: "Zaire", capital: "Mbanza Congo" },
    // { name: "Uíge", capital: "Uíge" },
    // { name: "Cuando Cubango", capital: "Menongue" }
    ])
    const [selected, setSelected] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [info, setInfo] = useState(null)
    const infoRef = useRef(null)
    const location = useLocation()
    useEffect(() => {
        if (!location.state?.info){
            window.scrollTo(0, 0)
        }else {
            setInfo(location.state?.info)
            
        }
    }, [])
    useEffect(() => {
        if (info && infoRef.current){
            const offsetPosition = infoRef.current.getBoundingClientRect().top + window.scrollY - 80; // 80px offset
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth' // for smooth scrolling
            });
              }
    }, [info])
    return (
        <div>
            <div id='interpro' style={{marginBottom: 80}}>
                <Dialog  open={dialogOpen} onOpenChange={(e) =>{
                                            true && setDialogOpen(e)
                                        }}>
                                            <DialogTrigger asChild>
            
                                            </DialogTrigger>
                                            <DialogContent style={{maxWidth: 750}}>
                                                <DialogTitle>Detalhes de viagem</DialogTitle>
                                                <ScrollArea style={{height: '100%', marginTop: 20}}>
                                                <div className='dialog'>
                                                    <div className='historyContainer'>
                                                        <div class={`timeline-block timeline-block-left}`}>
                                                            <div class="marker"></div>
                                                            <div class="timeline-content">
                                                                <h3>Partida</h3>
                                                                <span>às {format(new Date(), 'hh:mm aa')}</span>
                                                                <p>{selected?.name}</p>
                                                            </div>
                                                        </div>
                                                        <div class={`timeline-block timeline-block-left}`}>
                                                            <div class="marker"></div>
                                                            <div class="timeline-content">
                                                                <p>Duração</p>
                                                                <span>10 horas</span>
                                                            </div>
                                                        </div>
                                                        <div class={`timeline-block timeline-block-left}`}>
                                                            <div class="marker"></div>
                                                            <div class="timeline-content">
                                                                <h3>Chegada</h3>
                                                                <span>às {format(new Date(), 'hh:mm aa')}</span>
                                                                <p>{selected?.capital}</p>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    <div className='values'>
            
                                                        <div className='value'>
                                                            <label>De</label>
                                                            <span>{selected?.name}</span>
                                                        </div>
                                                        <div className='value'>
                                                        <label>Para</label>
                                                        <span>{selected?.capital}</span>
                                                    </div>
            
                                                                                        <div className='price'>
                                                                                            <div className='label'>Preço</div>
                                                                                            <span>KZ 23.000</span>
                                                                                        </div>
                                                                                        <div className='obs'>
                                                                                            <div className='label'>OBS</div>
                                                                                            <ul>
                    <li>1 bagagem de mão.</li>
                    <li>2 malas despachadas gratuitas</li>
                    <li>1 mala extra por passageiro (taxas aplicáveis)</li>
                    <li>Kz 3.0000 por bagagem despachada adicional</li>
                </ul>                                                                                    </div>
                                                    </div>
                                                    <div className='conditions'>
                                                        <div className='value'>
                                                            <label>Assentos</label>
                                                            <span>20 assentos restando (total 90 assentos)</span>
                                                        </div>
                                                        <div className='value'>
                                                            <label>Amenidades</label>
                                                            <ul>
                                                                <li>Wifi</li>
                                                                <li>Ar-condicionado</li>
                                                                <li>Tomadas elétricas</li>
                                                                <li>Televisões</li>
                                                            </ul>
                                                        </div>
                                                        <div className='valueButton' style={{fontWeight: 500, padding: 7, maxWidth: 300, width: '100%'}}>Seguir</div>
                                                    </div>
                                                </div>
                                                </ScrollArea>
            
                                            </DialogContent>
                                        </Dialog>
                <Header />
            
                <div className='interContainer'>
                    <div className='title' style={{marginTop: 70}}>Procure por uma viagem</div>
                </div>
                <div className='form'>
                    <div className='tags'>
                        <div onClick={() => {
                            setTag('Somente Ida')
                        }} className={tag.includes('Somente') ? 'tag': 'tag1'}>Somente Ida</div>
                        <div onClick={() => {
                            setTag('Ida e volta')
                        }} className={tag.includes('volta') ? 'tag': 'tag1'}>Ida e volta</div>
                    </div>
                    <div style={{ borderRadius: 10}} className='inputs'>
                    <Select className='select' onValueChange={(e) => {
            }}>
                <SelectTrigger className='select'>
            <SelectValue placeholder="Seleccione uma origem" />
                </SelectTrigger>
                <SelectContent>
            {angolaProvinces.map((item, index) => {
                return (
                    <SelectItem value={item?.name}>{item?.name}</SelectItem>
                )
            } )}
                </SelectContent>
                </Select>
            
                <Select className='select' onValueChange={(e) => {
            }}>
                <SelectTrigger className='select'>
            <SelectValue placeholder="Seleccione um destino" />
                </SelectTrigger>
                <SelectContent>
            {angolaProvinces.map((item, index) => {
                return (
                    <SelectItem value={item?.name}>{item?.name}</SelectItem>
                )
            } )}
                </SelectContent>
                </Select>
                <input className='loginInput' type='date' placeholder='do'/>
                {tag.includes('volta') && <input className='loginInput' type='date'/>
                }
            
                <div className='valueButton' style={{ padding: 8, margin: 0, background: '#C40000'}}>
            Procurar
                </div>
                    </div>
                </div>
                <div className='interContainer'>
                    <div className='left'>
                        <section className='filters'>
                            <div className='filterSection'>
                            </div>
                        </section>
                    </div>
                    <div className='right'>
                        {!info ? <>
                        <div className='title'>Recomendações</div>
                        <div className='locations'>
                            {[...destinations].map((item, index) => {
                                let random = Math.floor(Math.random(0, destinations.length - 1))
                                return (
                                    <div className='location'>
                                    <div className='imageContainer'>
                                        <img src={item?.image}/>
                                    </div>
                                    <div className='info'>
                                        <div className='locationTitle'>De: {item?.capital}</div>
                                        <div className='locationDescription'>Para: {destinations[random]?.capital}</div>
                                        <div className='locationDuration'>
                                            <div className='ini'>
                                                12:00
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
            </svg>
                                            <div className='duration'>
                                                10 horas
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
            </svg>
                                            <div className='final'>
                                                22:00
                                            </div>
                                        </div>
                                        <div className='priceRow'>
                                        <div className='price'>15.000 Kz</div>
                                        <div className='button' onClick={() => {
                                                // setSelected(item)
                                                // setDialogOpen(true)
                                                setInfo(item)
                                            }}>
                                                Ver mais horários
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                        </>:
                        <div>
                            <div className='title' ref={infoRef}>Detalhes</div>
                            <div className='info'>
                                <div className='routes'>
                                <div className='subTitle'><svg onClick={() => {
                                    setInfo(null)
                                }} style={{color: '#c40000'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg> {destinations.length} horários para: <span>{info?.name} - {info?.capital}</span></div>
                                    <div className='list'>
                                        {destinations.map((item) => {
                                            return (
                                                <div className='horario'>
                                                    <div>
                                                        <div className='horarioTitle'>{info?.name} - {info?.capital}</div>
                                                        <div className='angoDuration'>12 horas de viagem (sem paragens)</div>
                                                    </div>
                                                    <div className='arrivals'>
                                                        <div className='arrival'>
                                                            <span>Partida</span>
                                                            <div className='arrivalTime'>7:30 AM</div>
                                                            <div className='arrivalDate'>12/8/2024</div>
                                                        </div>
                                                        <div className='arrows'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                        </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                        </svg>
                                                        </div>
            <div className='angotrans'>
                <img src={process.env.PUBLIC_URL + '/images/logo.png'}/>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bus-front-fill" viewBox="0 0 16 16">
                  <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"/>
                </svg>
                12 horas (sem paragens)
            </div>
            <div className='arrows'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                        </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                                        </svg>
                                                        </div>
                                                        <div className='arrival'>
                                                            <span>Chegada</span>
                                                            <div className='arrivalTime'>6:30 PM</div>
                                                            <div className='arrivalDate'>12/8/2024</div>
                                                        </div>
                                                    </div>
                                                    <div className='horarioPrices'>
                                                        <div className='horarioPrice'>
                                                            <span>Preço: KZ 15.000</span>
                                                        </div>
                                                        <div className='bookButton' onClick={() => {
                                                            setSelected(info)
                                                            setDialogOpen(true)
                                                        }}>
                                                            Seguir
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='infoSection'></div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <Download />
            <Footer />
        </div>
    )
}
export default InterPro