import { useState } from "react"
import Header from "../components/header"
import '../css/checkout.css'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../@/components/ui/select'
import DatePicker, { registerLocale } from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, format, isSameDay } from "date-fns"
import { pt } from "date-fns/locale"
import { useLocation, useNavigate } from "react-router-dom"
registerLocale('pt', pt); // Register the Portuguese locale

const Checkout = () => {
  const location = useLocation()
  const navigate = useNavigate()
    const [steps, setSteps] = useState([
      {
        title: 'Viagem',
        description: 'Detalhes de viagem'
      },
      {
        title: 'Passageiros',
        description: 'Detalhes dos passageiros'
      },
      {
        title: 'Pagamento',
        description: 'Detalhes de pagamento'
      },
      {
        title: 'Confirmação',
        description: 'Confirme para finalizar o processo'
      },
    ])
    const [step, setStep] = useState([steps[0]])
    const [destinations, setDestinations] = useState([
      { name: "Luanda", capital: "Luanda", image: 'https://picsum.photos/id/277/1280/900.webp' },
    { name: "Benguela", capital: "Benguela", image: 'https://picsum.photos/id/251/1280/900.webp' },
    ])
    const [regresso, setRegresso] = useState(null)
    const [finalDate, setFinalDate] = useState(null)
    const [dates, setDates] = useState([
      
    ])
    const month = new Date().getMonth()
    const year = new Date().getFullYear()
    const [deactivatedDates, setDeactivatedDates] = useState([
      new Date(year, month, 15),
      new Date(year, month, 20),
      new Date(year, month, 25), 
    ]);
  
  
    const isDateDisabled = (date) => {
      return deactivatedDates.some(deactivatedDate => (isSameDay(date, deactivatedDate)));
    };
    const [horario, setHorario] = useState('')
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <Header />
            <div id="checkout">
                <div className="top">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                <svg onClick={() => {
                  if (step.length == 1){
                    navigate(-1)
                  }else {
                    setStep(step.filter((i, index) => index != step.length -1))
                  }
                }} style={{color: '#c40000', width: 25, height: 25, cursor: 'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
</svg>
                      <div className="step"><span>Passo {step.length}</span>: {step[step.length - 1].description}</div>
                </div>
                    <div className="progression">
                      {
                        steps.map((item, index) => {
                          return (
                            <div key={index} className={step.some(i => i?.title == item?.title) ? 'section1' : 'section'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bus-front-fill" viewBox="0 0 16 16">
                          <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"/>
                        </svg>
                                <span>{item?.title}</span>
                            </div>
                          )
                        })
                      }
                    </div>

                </div>

                {step[step.length -1].title == 'Viagem' ? <div className="trips">
                <div className='list'>
                  {destinations.slice(0, 2).map((item, index) => {
                      if (index == 0) {
                        return (
                          <div>
                            <div className="subTitle">
                              {index == 0 ? 'Partida' : 'Regresso'}
                            </div>
                            <div className='horario'>
                                <div>
                                    <div className='horarioTitle'>{item?.name} - {item?.capital}</div>
                                    <div className='angoDuration'>12 horas de viagem (sem paragens)</div>
                                </div>
                                <div className='arrivals'>
                                    <div className='arrival'>
                                        <span>Partida</span>
                                        <div className='arrivalTime'>7:30 AM</div>
                                        <div className='arrivalDate'>12/8/2024</div>
                                    </div>
                                    <div className='arrows'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg>
                                    </div>
                                        <div className='angotrans'>
                                            <img src={process.env.PUBLIC_URL + '/images/logo.png'}/>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bus-front-fill" viewBox="0 0 16 16">
                                              <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"/>
                                            </svg>
                                            12 horas (sem paragens)
                                        </div>
                                        <div className='arrows'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg>
                                    </div>
                                    <div className='arrival'>
                                        <span>Chegada</span>
                                        <div className='arrivalTime'>6:30 PM</div>
                                        <div className='arrivalDate'>12/8/2024</div>
                                    </div>
                                </div>
                                <div className='horarioPrices'>
                                    <div className='horarioPrice'>
                                        <span>Preço: KZ 15.000</span>
                                    </div>
                                    {/* <div className='bookButton' onClick={() => {
                                        // setSelected(info)
                                        // setDialogOpen(true)
                                    }}>
                                        Comprar agora
                                    </div> */}
                                </div>
                            </div>
                          </div>
                      )
                    }else {
                      if (finalDate && horario.length > 0){
                        return (
                          <div>
                            <div className="subTitle">
                              {index == 0 ? 'Partida' : 'Regresso'}
                            </div>
                            <div className='horario'>
                                <div>
                                    <div className='horarioTitle'>{item?.name} - {item?.capital}</div>
                                    <div className='angoDuration'>12 horas de viagem (sem paragens)</div>
                                </div>
                                <div className='arrivals'>
                                    <div className='arrival'>
                                        <span>Partida</span>
                                        <div className='arrivalTime'>7:30 AM</div>
                                        <div className='arrivalDate'>12/8/2024</div>
                                    </div>
                                    <div className='arrows'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg>
                                    </div>
                                        <div className='angotrans'>
                                            <img src={process.env.PUBLIC_URL + '/images/logo.png'}/>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bus-front-fill" viewBox="0 0 16 16">
                                              <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"/>
                                            </svg>
                                            12 horas (sem paragens)
                                        </div>
                                        <div className='arrows'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                                                    </svg>
                                    </div>
                                    <div className='arrival'>
                                        <span>Chegada</span>
                                        <div className='arrivalTime'>6:30 PM</div>
                                        <div className='arrivalDate'>12/8/2024</div>
                                    </div>
                                </div>
                                <div className='horarioPrices'>
                                    <div className='horarioPrice'>
                                        <span>Preço: KZ 15.000</span>
                                    </div>
                                    {/* <div className='bookButton' onClick={() => {
                                        // setSelected(info)
                                        // setDialogOpen(true)
                                    }}>
                                        Comprar agora
                                    </div> */}
                                </div>
                            </div>
                          </div>
                      )
                      }else {

                        return (
                          <div>
                            <div className="subTitle">Escolha data e hora para regresso</div>
                            <div className='form'>

<div style={{ borderRadius: 10}} className='inputs'>

<DatePicker
        className="loginInput" 
        locale={pt}
        selected={finalDate}
        onChange={(date) => setFinalDate(date)}
        filterDate={isDateDisabled} // Disable the dates
        placeholderText="Seleccione uma data..."
        // withPortal // Optional: Show the calendar in a portal
      />
{/* <input className='loginInput' type='date' placeholder='Selectionne data'/> */}
<Select className='select' onValueChange={(e) => {
  setHorario(e)
}}>
<SelectTrigger className='select'>
<SelectValue placeholder={finalDate ? 'Seleccione um horário' :"Seleccione primeiro uma data"} />
</SelectTrigger>
<SelectContent>
{[1, 2, 3, 4].map((item, index) => {

return (
<SelectItem value={`${format(addDays(new Date(), + item), 'dd/MM/yyyy')} - ${format(new Date(), 'dd/MM/yyyy')}`}>{format(new Date(), 'hh:mm')} - {format(new Date(), 'hh:mm')}</SelectItem>
)
} )}
</SelectContent>
</Select>
</div>
</div>
                          </div>
                        )
                      }
                    }
                  })}
              </div>
                <div className="continue" onClick={() => {
                  setStep([...step, steps[step.length]])
                }}>Continuar</div>
                </div>:
                step[step.length -1].title == 'Passageiros' && <div>
                  </div>}
            </div>
        </div>
    )
}
export default Checkout