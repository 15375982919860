import { useLocation, useNavigate } from "react-router-dom"
import Header from "../components/header"
import Footer from "../components/footer"
import '../css/newsPage.css'
import { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import { ScrollArea } from "../@/components/ui/scroll-area"
import '../css/newsPage.css'
import ReactPaginate from "react-paginate"
import Skeleton from "react-loading-skeleton"
import Download from "../components/download"
import axios from "axios"
import { baseURL, imageURL } from "../api/api"
const NewsPage = () => {
    const navigate = useNavigate()
    const [section, setSection] = useState('Notícias')
    const [placeholder, setPlaceholder] = useState([
        {
            id: 1,
            title: 'Embaixador da China acreditado em Angola interessado em cooperar com o ISPAJ no domínio da saúde',
            date: Date.now(),
            image: process.env.PUBLIC_URL + '/images/post1.png'
        },
        {
            id: 2,
            title: 'AGENDA 2030 DA ONU FOI OBJECTO DE ESTUDO NAS VIII JORNADAS CIENTÍFICAS DO ISPAJ',
            date: Date.now(),
            image: process.env.PUBLIC_URL + '/images/post2.png'
        },
        {
            id: 3,
            title: 'Quer saber mais sobre o ANGOSAT 2?',
            date: Date.now(),
            image: process.env.PUBLIC_URL + '/images/post5.png'
        },
        {
            id: 4,
            title: 'Decorre hoje, no Bairro 28 de Agosto “Vulgo Lixeira” a Feira solidária da saúde organizada pelo ISPAJ',
            date: Date.now(),
            image: process.env.PUBLIC_URL + '/images/post3.png'

        },
        {
            id: 5,
            title: 'Marketing: estudantes do curso de Gestão Empresarial do ISPAJ submetidos a desafio',
            date: Date.now(),
            image: process.env.PUBLIC_URL + '/images/post6.png'

        },
        {
            id: 6,
            title: 'ISPAJ Presente na I Conferência Internacional CAEP',
            date: Date.now(),
            image: process.env.PUBLIC_URL + '/images/post7.png'
            
        },  
    ])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('recent')
    const [posts, setPosts] = useState([])
    const [posts2, setPosts2] = useState([])
    const [posts1, setPosts1] = useState([])
    const [postPerPage, setPostPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [pages, setPages] = useState([])
    const scrollDivRef = useRef(null)
    let list = [
        {
            "id": 1,
            "info": {
                "data": "2023-10-01",
                "titulo": "Lançamento da nova linha de ônibus para o centro da cidade",
                "imagem": "url-da-imagem-1.jpg"
            }
        },
        {
            "id": 2,
            "info": {
                "data": "2023-10-05",
                "titulo": "AngoTrans celebra 10 anos de serviços de transporte",
                "imagem": "url-da-imagem-2.jpg"
            }
        },
        {
            "id": 3,
            "info": {
                "data": "2023-10-12",
                "titulo": "Descubra os novos horários da linha 305",
                "imagem": "url-da-imagem-3.jpg"
            }
        },
        {
            "id": 4,
            "info": {
                "data": "2023-10-15",
                "titulo": "Promoções de fim de semana: viajem com desconto!",
                "imagem": "url-da-imagem-4.jpg"
            }
        },
        {
            "id": 5,
            "info": {
                "data": "2023-10-18",
                "titulo": "AngoTrans implementa novo sistema de venda de ingressos online",
                "imagem": "url-da-imagem-5.jpg"
            }
        },
        {
            "id": 6,
            "info": {
                "data": "2023-10-20",
                "titulo": "Melhorias na frota: conheça nossos novos ônibus",
                "imagem": "url-da-imagem-6.jpg"
            }
        },
        {
            "id": 7,
            "info": {
                "data": "2023-10-22",
                "titulo": "Segurança em primeiro lugar: treinamentos para motoristas",
                "imagem": "url-da-imagem-7.jpg"
            }
        },
        {
            "id": 8,
            "info": {
                "data": "2023-10-25",
                "titulo": "Aumentamos a frequência nas linhas mais populares",
                "imagem": "url-da-imagem-8.jpg"
            }
        },
        {
            "id": 9,
            "info": {
                "data": "2023-11-01",
                "titulo": "Importante: mudança no itinerário da linha 50",
                "imagem": "url-da-imagem-9.jpg"
            }
        },
        {
            "id": 10,
            "info": {
                "data": "2023-11-03",
                "titulo": "Novos pontos de ônibus em áreas estratégicas",
                "imagem": "url-da-imagem-10.jpg"
            }
        },
        {
            "id": 11,
            "info": {
                "data": "2023-11-05",
                "titulo": "Dia Mundial do Trânsito: evento especial com a AngoTrans",
                "imagem": "url-da-imagem-11.jpg"
            }
        },
        {
            "id": 12,
            "info": {
                "data": "2023-11-10",
                "titulo": "Feedback dos usuários: ajudando a melhorar nossos serviços",
                "imagem": "url-da-imagem-12.jpg"
            }
        },
        {
            "id": 13,
            "info": {
                "data": "2023-11-15",
                "titulo": "Campanha de conscientização sobre uso de transporte público",
                "imagem": "url-da-imagem-13.jpg"
            }
        },
        {
            "id": 14,
            "info": {
                "data": "2023-11-20",
                "titulo": "Transporte sustentável: AngoTrans adota ônibus elétricos",
                "imagem": "url-da-imagem-14.jpg"
            }
        },
        {
            "id": 15,
            "info": {
                "data": "2023-11-25",
                "titulo": "Horários especiais para o Natal e Ano Novo",
                "imagem": "url-da-imagem-15.jpg"
            }
        },
        {
            "id": 16,
            "info": {
                "data": "2023-11-30",
                "titulo": "Concurso cultural: ganhe viagens grátis!",
                "imagem": "url-da-imagem-16.jpg"
            }
        },
        {
            "id": 17,
            "info": {
                "data": "2023-12-02",
                "titulo": "Mudanças no regulamento do uso de máscaras nos ônibus",
                "imagem": "url-da-imagem-17.jpg"
            }
        },
        {
            "id": 18,
            "info": {
                "data": "2023-12-05",
                "titulo": "Transporte de cargas: novos serviços da AngoTrans",
                "imagem": "url-da-imagem-18.jpg"
            }
        },
        {
            "id": 19,
            "info": {
                "data": "2023-12-10",
                "titulo": "AngoTrans se une a comunidades para melhorar o serviço",
                "imagem": "url-da-imagem-19.jpg"
            }
        },
        {
            "id": 20,
            "info": {
                "data": "2023-12-15",
                "titulo": "Promoção de fim de ano: passagens com 50% de desconto",
                "imagem": "url-da-imagem-20.jpg"
            }
        },
        {
            "id": 21,
            "info": {
                "data": "2023-12-20",
                "titulo": "O impacto do COVID-19 no transporte público: uma análise",
                "imagem": "url-da-imagem-21.jpg"
            }
        },
        {
            "id": 22,
            "info": {
                "data": "2023-12-25",
                "titulo": "Mensagem de Natal da equipe AngoTrans",
                "imagem": "url-da-imagem-22.jpg"
            }
        },
        {
            "id": 23,
            "info": {
                "data": "2023-12-30",
                "titulo": "Retrospectiva do ano: os principais momentos da AngoTrans",
                "imagem": "url-da-imagem-23.jpg"
            }
        },
        {
            "id": 24,
            "info": {
                "data": "2024-01-01",
                "titulo": "Novidades para 2024: o que esperar da AngoTrans?",
                "imagem": "url-da-imagem-24.jpg"
            }
        },
        {
            "id": 25,
            "info": {
                "data": "2024-01-05",
                "titulo": "Início do ano letivo: ajustes nos horários das linhas escolares",
                "imagem": "url-da-imagem-25.jpg"
            }
        }
    ]
    // useEffect(() => {
        
    //     setPosts(list)
    //     setPosts2(list)
    //     for (let i = 1; i <= Math.ceil([...list].length/postPerPage); i++) {
    //         setPages([...pages, i])
    //     }
    //     const lastPostIndex = currentPage * postPerPage
    //     const firstPostIndex = lastPostIndex - postPerPage
    //     const currentPosts = [...list].filter((item) => {
    //         if (item.info?.show == undefined){
    //             return item
    //         }else if (item.info?.show == true){
    //             return item
    //         }
    //     }).slice(firstPostIndex, lastPostIndex)
    //     setPosts1(currentPosts.slice(2))
        
        
        
        
    // }, [])
    useEffect(() => {
        axios.get(`${baseURL}/news`)
        .then(res => {
            setPosts([...res.data].filter((item) => {
                if (item.info?.show == undefined){
                    return item
                }else if (item.info?.show == true){
                    return item
                }
            }))
            setPosts2([...res.data].filter((item) => {
                if (item.info?.show == undefined){
                    return item
                }else if (item.info?.show == true){
                    return item
                }
            }))
            for (let i = 1; i <= Math.ceil([...res.data].length/postPerPage); i++) {
                setPages([...pages, i])
            }
            const lastPostIndex = currentPage * postPerPage
            const firstPostIndex = lastPostIndex - postPerPage
            const currentPosts = [...res.data].filter((item) => {
                if (item.info?.show == undefined){
                    return item
                }else if (item.info?.show == true){
                    return item
                }
            }).slice(firstPostIndex, lastPostIndex)
            setPosts1(currentPosts.slice(2))
        })
        .catch(err => {
            // toast(err.response.data.message)
        })
        
        
        
    }, [])
    useEffect(() => {
        if (searched.length > 0){
            //('changed', posts)
            const lastPostIndex = currentPage * postPerPage
            const firstPostIndex = lastPostIndex - postPerPage
            const currentPosts = posts2.slice(firstPostIndex, lastPostIndex)
            setPosts1(currentPosts)
        }else {
            //('changed', posts)
            const lastPostIndex = currentPage * postPerPage
            const firstPostIndex = lastPostIndex - postPerPage
            const currentPosts = posts2.slice(firstPostIndex, lastPostIndex).filter((item) => item.id != posts[0]?.id && item.id != posts[1]?.id)
            setPosts1(currentPosts.filter((item) => item.id != posts[0]?.id && item.id != posts[1]?.id))
        }
    }, [posts2, currentPage, filter, posts])
    const [searched, setSearched] = useState('')
    const look = () => {
        if (search.length == 0){
            // toast('Digite algo para ver resultados')
            setSearched(search)
            setPosts2(posts)
        }else {
            setSearched(search)
            setCurrentPage(1)
            setPosts2(posts.filter((item) => `${item.info.titulo}`.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
            // scrollDivRef.current && window.scrollTo({
            //     top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
            // })
        }
    }
    const location = useLocation()
    const prevLocationRef = useRef(location);

    useEffect(() => {
      if (prevLocationRef.current.pathname !== location.pathname) {
        window.location.reload();
        window.scrollTo(0, 0)
    }
    prevLocationRef.current = location;

  }, [location])
  useEffect(() => {
    
    
    window.scrollTo(0, 0)
  }, [])

    return (
        <div className="abtCont" >
            <Header />
           
            <div className="aboutFirst" style={{marginTop: 70, maxWidth: 1600, padding: '0 0px'}}>
              {/* <div className="navigation">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.5.5 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.5.5 0 0 0-.196 0zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1z"/>
                  </svg>
                  <span onClick={() => {
                              navigate('/')
                          }}>Home</span> <span>{'>'}</span><span>{section}</span>
                </div> */}
            </div>
            
            <div className="aboutContainer" style={{maxWidth: 1600, padding: '0 0px'}}>
                
                <section className="secondAboutSection" style={{flex: 1}}>
                <div className='healthContainer'>
            
            <section className="secondContainer" style={{}}>
                <div className='aboutCourse'>
                    <div className="title">Notícias</div>
                    <div className="newsHeader">
                        <div className="searchContainer">
                            <div className="search">
                                <span onClick={() => {
                                    look()
                                }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                        </svg></span>
                                <input placeholder="Pesquise algo..." value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={(e) => {
                            if (e.key == 'Enter'){
                                
                                look()
                                
                            } 
                        }}/>
                                
                            </div>
                        </div>
                        <div className="tabs">
                            <div onClick={() => {
                                if (!filter.includes('recent') && posts.length > 2){
                                    setFilter('recent')
                                    let p = posts
                                    setPosts2(posts2.sort((a, b) => new Date(b?.info?.data) - new Date(a?.info?.data))
                                )
                                setCurrentPage(1)
                                }

                                scrollDivRef.current && window.scrollTo({
                                    top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
                                })
                            }} className={`tab ${filter.includes('recent') && 'tab1'}`}>Recentes</div>
                            <div onClick={() => {
                                if (!filter.includes('top')&& posts.length > 2){
                                    setFilter('top')
                                    let p = posts
                                    //('zodf', posts2.sort((a, b) => b.info.views - a.info.views).map((item)=>item.info.views))
                                    setPosts2(posts2.sort((a, b) => new Date(b?.info?.data) - new Date(a?.info?.data)).sort((a, b) => b.info.views - a.info.views)
                                )
                                setCurrentPage(1)
                                }

                                scrollDivRef.current && window.scrollTo({
                                    top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
                                })
                            }} className={`tab ${filter.includes('top') && 'tab1'}`}>Mais Vistos</div>
                            <div onClick={() => {
                                if (!filter.includes('old') && posts.length > 2){
                                    setFilter('old')
                                    let p = posts

                                    setPosts2(posts2.sort((a, b) => new Date(a?.info?.data) - new Date(b?.info?.data))
                                )
                                setCurrentPage(1)
                                }

                                scrollDivRef.current && window.scrollTo({
                                    top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
                                })
                            }} className={`tab ${filter.includes('old') && 'tab1'}`}>Mais Antigos</div>
                        </div>
                    </div>
                    {!searched.length > 0 && <div className="newsBanners">
                    <div className="newsBanner">
                    
                    <div className="infoContainer">
                        <div className="glance">Em Destaque</div>
                        {posts.length > 2 && <img loading="lazy"style={{zIndex: 0}} src={`${imageURL}/${[...posts[0].imagens][0]}`}/>}
                        <div className='info' style={{zIndex: 1}}>
                            {posts.length > 2 && <div className='postDate'>{new Date(posts[0]?.info?.data).getDate()}/{new Date(posts[0]?.info?.data).getMonth() + 1}/{new Date(posts[0]?.info?.data).getFullYear()}</div>}
                            {posts.length > 2 && <div style={{marginBottom: 10, textTransform: 'uppercase'}} className='postTitle'>{posts[0]?.info?.titulo}</div>}
                            <div onClick={() => {
                                if (posts.length > 2){
                                    navigate(`/news/${posts[0]?.id}`)
                                }
                            }} style={{}}className='valueButton'>Ler Mais</div>
                        </div>
                    </div>
                    </div>
                    <div className="newsBanner">
                    {posts.length > 2 && <img loading="lazy"style={{opacity: 1}} src={`${imageURL}/${[...posts[1].imagens][0]}`}/>}
                    <div className="infoContainer">
                        <div className="glance">Em Destaque</div>

                        <div className='info'>
                            <div className='postDate'>{new Date(posts[1]?.info?.data).getDate()}/{new Date(posts[1]?.info?.data).getMonth() + 1}/{new Date(posts[1]?.info?.data).getFullYear()}</div>
                            {posts.length > 2 && <div style={{marginBottom: 10}} className='postTitle'>{posts[1]?.info?.titulo}</div>}
                            <div onClick={() => {
                                if (posts.length > 2) {
                                    navigate(`/news/${posts[1]?.id}`)
                                }
                            }} style={{}}className='valueButton'>Ler Mais</div>
                        </div>
                    </div>
                    </div>
                    </div>}
                    {searched.length > 0 && <div onClick={()=> {
                        setSearch('')
                        setSearched('')
                        setPosts2(posts)
                        setFilter('recent')
                        setCurrentPage(1)
                    }} style={{width: '100%'}} className="valueButton">Voltar a ver todas as notícias</div>}
                    {searched.length > 0 ? 
                    <div>
                        <div style={{}} className="subTitle" ref={scrollDivRef}>Resultados para "{searched}"<span>({posts2.length}) </span> <div style={{display: 'inline-flex', flexDirection: 'row', alignItems: 'center', marginLeft:10}}><span style={{color: '#d88c00', fontWeight: '500'}}>{filter == 'top' ? 'Mais Vistos' : filter == 'old' ? 'Mais Antigos' : 'Recentes'}</span></div></div>

                        <div></div>
                    </div>
                    :<div style={{}} className="subTitle" ref={scrollDivRef}>Veja mais <span>({posts2.length - 2 < 0 ? 0 : posts2.length - 2}) </span> <div style={{display: 'inline-flex', flexDirection: 'row', alignItems: 'center', marginLeft:10}}><span style={{color: '#C40000', fontWeight: '500'}}>{filter == 'top' ? 'Mais Vistos' : filter == 'old' ? 'Mais Antigos' : 'Recentes'}</span></div></div>}
                    {
                        searched.length > 0 ? 
                        <>
                        <ReactPaginate
                    
                    breakLabel="..."
                            nextLabel=">"
                            onPageChange={(e) => {
                                setCurrentPage(e.selected + 1)
                                
                                scrollDivRef.current && window.scrollTo({
                                    top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
                                })
                            }}
                            
                            forcePage={currentPage-1}
                            pageRangeDisplayed={5}
                            pageCount={posts1.slice(0, Math.ceil((posts1.length) /postPerPage)).map((item, index) => index + 1).length}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'prev-item'}
                            previousLinkClassName={'prev-link'}
                            nextClassName={'next-item'}
                            nextLinkClassName={'next-link'}
                            breakLinkClassName={'break-link'}
                            activeClassName={'active'}
                            disabledClassName={'disabled'}
                            />
                   {posts1.length > 0 ? <div className="postGrid">
                        {posts1.map((post, index) => {
                            return (
                                <div key={index} className="newsBanner1" style={{}}>
                        <img loading="lazy" src={`${imageURL}/${[...post?.imagens][0]}`}/>
                        <div className="infoContainer">
                            <div className="glance">Em Destaque</div>
                            <div className='info'>
                                <div className='postDate'>{new Date(post?.info?.data).getDate()}/{new Date(post?.info?.data).getMonth() + 1}/{new Date(post?.info?.data).getFullYear()}</div>
                                <div style={{marginBottom: 10, textTransform: 'uppercase'}} className='postTitle'>{post?.info?.titulo}</div>
                                <div style={{}}className='valueButton' onClick={() => {
                                    // navigate(`/noticias/${post.id}`)
                                    navigate(`/news/${post.id}`)
                                }}>Ler Mais</div>
                            </div>
                        </div>
                        </div>
                            )
                        })}
                    </div>: <div>Nenhum resultado.</div>
                    }
                    <ReactPaginate
                    
                    breakLabel="..."
                            nextLabel=">"
                            onPageChange={(e) => {
                                setCurrentPage(e.selected + 1)
                                
                                scrollDivRef.current && window.scrollTo({
                                    top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
                                })
                            }}
                            
                            forcePage={currentPage-1}
                            pageRangeDisplayed={5}
                            pageCount={posts1.slice(0, Math.ceil((posts1.length) /postPerPage)).map((item, index) => index + 1).length}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'prev-item'}
                            previousLinkClassName={'prev-link'}
                            nextClassName={'next-item'}
                            nextLinkClassName={'next-link'}
                            breakLinkClassName={'break-link'}
                            activeClassName={'active'}
                            disabledClassName={'disabled'}
                            />
                    </>
                            
                            :
                            <>
                            
                            <ReactPaginate
                    
                    breakLabel="..."
                            nextLabel=">"
                            onPageChange={(e) => {
                                setCurrentPage(e.selected + 1)
                                
                                scrollDivRef.current && window.scrollTo({
                                    top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
                                })
                            }}
                            
                            forcePage={currentPage-1}
                            pageRangeDisplayed={5}
                            pageCount={posts2.slice(0, Math.ceil((posts2.length -2) /postPerPage)).map((item, index) => index + 1).length}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'prev-item'}
                            previousLinkClassName={'prev-link'}
                            nextClassName={'next-item'}
                            nextLinkClassName={'next-link'}
                            breakLinkClassName={'break-link'}
                            activeClassName={'active'}
                            disabledClassName={'disabled'}
                            />
                    <div className="postGrid">
                        
                        
                        {posts.length > 2 ? 
                        posts1.map((post, index) => {
                            return (
                                <div key={index} className="newsBanner1" style={{}}>
                                    
                        <img loading="lazy"src={`${imageURL}/${[...post?.imagens][0]}`}/>
                        <div className="infoContainer">
                            <div className="glance">Em Destaque</div>
                            <div className='info'>
                                <div className='postDate'>{new Date(post?.info?.data).getDate()}/{new Date(post?.info?.data).getMonth() + 1}/{new Date(post?.info?.data).getFullYear()}</div>
                                <div style={{marginBottom: 10, textTransform: 'uppercase'}} className='postTitle'>{post?.info?.titulo}</div>
                                <div style={{}}className='valueButton' onClick={() => {
                                    // navigate(`/noticias/${post.id}`)
                                    navigate(`/news/${post.id}`)
                                }}>Ler Mais</div>
                            </div>
                        </div>
                        </div>
                            )
                        })
                        : placeholder.concat(placeholder).map((post, index) => {
                            return (
                                <div key={index} className="newsBanner1" style={{}}>
                                            <img loading="lazy"style={{opacity: 0}} />
                                            <div className="infoContainer" style={{background: 'white'}}>
                                                <div className="glance">Em Destaque</div>
                                                <div className='info'>
                                                    {/* <div className='postDate'>{new Date(post.date).getDate()}/{new Date(post.date).getMonth() + 1}/{new Date(post.date).getFullYear()}</div> */}
                                                    <Skeleton />
                                                    <Skeleton className="postTitle"/>
                                                    {/* <div style={{marginBottom: 10}} className='postTitle'>{post.title}</div> */}
                                                    <Skeleton style={{width: 100, height: 35}}/>
                                                    
                                                </div>
                                            </div>
                                            </div>
                            )
                        })}
                    </div>
                    <ReactPaginate
                    
                    breakLabel="..."
                            nextLabel=">"
                            onPageChange={(e) => {
                                setCurrentPage(e.selected + 1)
                                scrollDivRef.current && window.scrollTo({
                                    top: scrollDivRef.current.getBoundingClientRect().top + window.scrollY - 120
                                })
                            }}
                            pageRangeDisplayed={5}
                            pageCount={posts2.slice(0, Math.ceil((posts2.length - 2)/postPerPage)).map((item, index) => index + 1).length}
                            forcePage={currentPage-1}

                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'prev-item'}
                            previousLinkClassName={'prev-link'}
                            nextClassName={'next-item'}
                            nextLinkClassName={'next-link'}
                            breakLinkClassName={'break-link'}
                            activeClassName={'active'}
                            disabledClassName={'disabled'}
                            /></>
                    }
                    <div>
                        
                    </div>
                </div>

                {/*  */}

               
            </section>
        </div>
                </section>
            </div>
            <Download />
            <Footer />
        </div>
    )
}
export default NewsPage
