import { Edit, HeaderLabel } from "./adminHome"
import '../css/adminInterprovincial.css'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../@/components/ui/dialog"
import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { baseURL, imageURL } from "../api/api"
import { useLocation, useNavigate } from "react-router-dom"
import getCroppedImg from "./cropImage"
import Cropper from "react-easy-crop"
import ReactQuill from "react-quill"
import { ScrollArea } from "../@/components/ui/scroll-area"
import Lottie from "lottie-react"
import Loading1 from "./loading1"
import ReactSwitch from "react-switch"

const AdminInterprovincial = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const [errors, setErrors] = useState([])
    const [messages, setMessages] = useState([])
    const [load, setLoad] = useState(false)
    const [title, setTitle] = useState('')
    const [destinations, setDestinations] = useState([
        { name: "Luanda", capital: "Luanda", image: 'https://picsum.photos/id/277/1280/900.webp' },
    { name: "Benguela", capital: "Benguela", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Huambo", capital: "Huambo", image: 'https://picsum.photos/id/66/1280/900.webp' },
    { name: "Huíla", capital: "Lubango", image: 'https://picsum.photos/id/350/1280/900.webp' },
    { name: "Namibe", capital: "Moçâmedes", image: 'https://picsum.photos/id/221/1280/900.webp' },
    { name: "Kwanza Norte", capital: "N'dalatando", image: 'https://picsum.photos/id/251/1280/900.webp' },
    { name: "Kwanza Sul", capital: "Sumbe", image: 'https://picsum.photos/id/313/1280/900.webp' },
    { name: "Malanje", capital: "Malanje", image: 'https://picsum.photos/id/15/1280/900.webp' },
    { name: "Lunda Norte", capital: "Lunda", image: 'https://picsum.photos/id/168/1280/900.webp' },
    { name: "Lunda Sul", capital: "Saurimo", image: 'https://picsum.photos/id/368/1280/900.webp' },
    { name: "Moxico", capital: "Luena", image: 'https://picsum.photos/id/542/1280/900.webp' },
    { name: "Cunene", capital: "Ondjiva", image: 'https://picsum.photos/id/851/1280/900.webp' },
    // { name: "Bengo", capital: "Caxito" },
    // { name: "Cabinda", capital: "Cabinda" },
    // { name: "Zaire", capital: "Mbanza Congo" },
    // { name: "Uíge", capital: "Uíge" },
    // { name: "Cuando Cubango", capital: "Menongue" }
    ])
    const [selectedImage, setSelectedImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)
    const [crop, setCrop]= useState({x: 0, y: 0})
    const [zoom, setZoom]= useState(1)
    const [completedCrop, setCompletedCrop] = useState(null)
    const imageRef = useRef(null)    
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [aboutContent, setAboutContent] = useState(null)
    const [show, setShow] = useState(false)
    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
        const croppedImageUrl = await getCroppedImg(selectedImage, croppedAreaPixels)
        setCompletedCrop(croppedImageUrl)

    }
    const onCrop = async () => {
        const croppedImageUrl = await getCroppedImg(selectedImage, croppedAreaPixels)
        setCompletedCrop(croppedImageUrl)
    }
    return (
        <div className="dashboardContainer" id='adminHomeContainer'>
            <Loading1 loading={loading}/>
            <div className="title">Interprovincial (pontos de partida)</div>
            
            <Dialog>
                            <DialogTrigger onClick={() => {
                               
                                
                                setSelectedImage(null)
                                setCompletedCrop(null)
                            }}>
<div style={{background: '#F7F7F7', fontSize: 13, width: 'max-content', padding: 10, borderRadius: 5, color: 'black', fontWeight: '500', cursor: 'pointer', border: '1px solid #e2e2e2'}}>Adicionar ponto</div>                            
</DialogTrigger>
                            <DialogContent style={{width: '100%', maxWidth: 1000, display: 'flex', flexDirection: 'column'}}>
                            <DialogHeader>
                            <DialogTitle>Adicionar</DialogTitle>
                            <DialogDescription>
                                Adicionar ponto de partida
                            </DialogDescription>
                            </DialogHeader>
                            
                            <ScrollArea style={{height: '80vh', marginTop: 20}}>
                                <div className='sectionForms'>
                                <div className="form">
                                        <div
                                        className="label">Nome do província</div>
                                        <input value={title} onChange={(e)=> {
                                            setTitle(e.target.value)
                                        }} placeholder="Ex: Luanda..." className="loginInput"/>
                                    </div>
                                    <div className='form'>
                                            <div className='label'>Image do local</div>
                                            <input className='imageInput1' type='file' accept='image/*'  onChange={(e)=>{
                                        let file = e.target.files[0]
                                        if (file){
                                            const reader = new FileReader()
                                            reader.onload = (event) => {
                                                setSelectedImage(event.target.result)
                                                //('data', event.target.result)
                                            }
                                            reader.readAsDataURL(file)
                                        }else {
                                            setSelectedImage(null)
                                        }
                                    }}/>
                                    {/* <div className='selectedImage'>
                                        <img loading="lazy"src={selectedImage}/>
                                    </div> */}
                                     {selectedImage && <div style={{color: 'red', fontSize: 14, cursor: 'pointer', fontWeight: 600}} onClick={() => {
                                                setSelectedImage(null)
                                                setCompletedCrop(null)
                                            }}>Remover imagem</div>}
                                            {selectedImage && <div style={{height: '100%', aspectRatio: 4/2, position: 'relative'}}>
                                                <Cropper
                                                
                                                style={{height: '100%', width: '100%', position: 'relative'}}
                                                image={selectedImage}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={4/2.2}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                                />
                                            </div>}
                                    
                                    
                                    {(!completedCrop && imagePreview) && <div style={{aspectRatio: '4/2', background: '#d3d3d3', borderRadius: 5, overflow: 'hidden'}}>
                                        <img loading="lazy"style={{width: '100%', height: '100%', objectFit: 'cover'}} src={`${imageURL}/${imagePreview}`}/>
                                    </div>}
                                        </div>
                                
                                <div className="errors">
                                {errors.length > 0 && errors.map((item, index) => {
                                    return (
                                        <div className="error" key={index}>
                                            {item}
                                        </div>
                                    )
                                })}
                                <div className="errors">
                                {messages.length > 0 && messages.map((item, index) => {
                                    return (
                                        <div style={{color: 'green'}}  className="error" key={index}>
                                            {item}
                                        </div>
                                    )
                                })}
                                                        </div>
                                                        </div>
                                                        {load && <Lottie rendererSettings={
                            {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                            } autoplay loop animationData={require('../components/loading1.json')}style={{width: 100, height: 100}} className='lottieItem'/>}
                                    <div className='buttons' style={{marginBottom: 50}}>
                                        <div onClick={() => {
                                            // addFrota()
                                        }} className='save'>Adicionar</div>
                                    </div>
                                    {/* <div className='' dangerouslySetInnerHTML={{__html: prMessage}}>
                                    </div> */}
                                
                                
                                </div>
                            </ScrollArea>
                            </DialogContent>
                        </Dialog>
            <div style={{display: 'grid'}} className="first" id='first2'>
            {destinations.length > 0 ? [...destinations].map((item, index) => {
                return (
                    <section className="section">
                <div className="header">
                    <HeaderLabel title={item?.name}/>
                    <Dialog>
                        <DialogTrigger onClick={() => {
                            // setTitle(item?.titulo||'')
                            // setDescription(item?.description)
                            
                            // setImagePreview(item?.imagem)
                            // //('info', departments[0]?.info)
                            // item?.status == 'activo' ? setShow(true) : item?.status == 'inactivo' && setShow(false)

                            // setSelectedImage(null)
                            // setCompletedCrop(null)
                        }}>
                            <Edit title='Editar'/>
                        </DialogTrigger>
                        <DialogContent style={{width: '100%', maxWidth: 1000, display: 'flex', flexDirection: 'column'}}>
                        <DialogHeader>
                        <DialogTitle>Editar</DialogTitle>
                        <DialogDescription>
                            Editar ponto de partida
                        </DialogDescription>
                        </DialogHeader>
                        
                        <ScrollArea style={{height: '80vh', marginTop: 20}}>
                            <div className='sectionForms'>
                            <div className="form">
                                        <div
                                        className="label">Nome do província</div>
                                        <input value={title} onChange={(e)=> {
                                            setTitle(e.target.value)
                                        }} placeholder="Ex: Luanda..." className="loginInput"/>
                                    </div>
                                    <div className='form'>
                                            <div className='label'>Image do local</div>
                                            <input className='imageInput1' type='file' accept='image/*'  onChange={(e)=>{
                                        let file = e.target.files[0]
                                        if (file){
                                            const reader = new FileReader()
                                            reader.onload = (event) => {
                                                setSelectedImage(event.target.result)
                                                //('data', event.target.result)
                                            }
                                            reader.readAsDataURL(file)
                                        }else {
                                            setSelectedImage(null)
                                        }
                                    }}/>
                                    {/* <div className='selectedImage'>
                                        <img loading="lazy"src={selectedImage}/>
                                    </div> */}
                                     {selectedImage && <div style={{color: 'red', fontSize: 14, cursor: 'pointer', fontWeight: 600}} onClick={() => {
                                                setSelectedImage(null)
                                                setCompletedCrop(null)
                                            }}>Remover imagem</div>}
                                            {selectedImage && <div style={{height: '100%', aspectRatio: 4/2, position: 'relative'}}>
                                                <Cropper
                                                
                                                style={{height: '100%', width: '100%', position: 'relative'}}
                                                image={selectedImage}
                                                crop={crop}
                                                zoom={zoom}
                                                aspect={4/2.2}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                                />
                                            </div>}
                                    
                                    
                                    {(!completedCrop && imagePreview) && <div style={{aspectRatio: '4/2', background: '#d3d3d3', borderRadius: 5, overflow: 'hidden'}}>
                                        <img loading="lazy"style={{width: '100%', height: '100%', objectFit: 'cover'}} src={`${imageURL}/${imagePreview}`}/>
                                    </div>}
                                        </div>
                            <div className='form'>
                                <ReactSwitch uncheckedIcon={null} checkedIcon={null} checked={show} onChange={() => {
                                    setShow(!show)
                                }} />
                            </div>
                            <div className="errors">
                            {errors.length > 0 && errors.map((item, index) => {
                                return (
                                    <div className="error" key={index}>
                                        {item}
                                    </div>
                                )
                            })}
                            <div className="errors">
                            {messages.length > 0 && messages.map((item, index) => {
                                return (
                                    <div style={{color: 'green'}}  className="error" key={index}>
                                        {item}
                                    </div>
                                )
                            })}
                                                    </div>
                                                    </div>
                                                    {load && <Lottie rendererSettings={
                        {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                        } autoplay loop animationData={require('../components/loading1.json')}style={{width: 100, height: 100}} className='lottieItem'/>}
                                <div className='buttons' style={{marginBottom: 50}}>
                                    <div onClick={() => {
                                    }} className='save'>Guardar</div>
                                </div>
                               
                            
                            
                            </div>
                        </ScrollArea>
                        </DialogContent>
                    </Dialog>
                    
                </div>
                <div className="header">
                        <div onClick={() => {
                            navigate('/admin/transporte/interprovincial/ponto')
                            // localStorage.setItem('department', JSON.stringify(departments[0]))
                        }}>
                            <Edit title='Ver rotas'/>
                        </div>
                    </div>
                
                <div className="info">
                    <div className="basicInfo">{item?.description}
                        
                    </div>
                    <div className="videoCont">
                        {<img loading="lazy"  style={{width: '100%', height: '100%', objectFit: 'cover'}} src={item?.image} loop muted controls />}
                    </div>
                </div>
            </section>
                )
            }): 
            <div style={{fontSize: 14, textAlign: 'left'}}>Nenhuma frota adicionada ainda. Adicione uma.</div>}
                
            </div>

        </div>
    )
}
export default AdminInterprovincial