import { useState } from 'react'
import '../css/adminSide.css'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../@/components/ui/accordion"

import { Popover, PopoverContent, PopoverTrigger } from '../@/components/ui/popover'

const AdminSide = () => {

    const navigate = useNavigate()
    const location = useLocation()
    return (
        <div className="adminSide">
            <div className={location.pathname.endsWith('/admin') ? 'option1' : 'option'} onClick={() => {
              navigate('/admin')
              localStorage.setItem('path', 'Dashboard')
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-border-all" viewBox="0 0 16 16">
  <path d="M0 0h16v16H0zm1 1v6.5h6.5V1zm7.5 0v6.5H15V1zM15 8.5H8.5V15H15zM7.5 15V8.5H1V15z"/>
</svg>
            Painel
            </div>
            <div onClick={() => {
              navigate('/admin/home')
              localStorage.setItem('path', 'home')
            }} className={location.pathname.endsWith('/admin/home') ? 'option1' : 'option'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
</svg>
            Página Inicial
            </div>
            {/* <div onClick={() => {
              navigate('/admin/calendario')
              localStorage.setItem('path', 'home')
            }} className={location.pathname.includes('admin/calendario') ? 'option1' : 'option'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
</svg>
            Calendário
            </div> */}
            
            
            <Accordion type="single" collapsible>
              <AccordionItem style={{borderBottom: 'none', textDecoration: 'none'}} value="item-1">
                <AccordionTrigger style={{borderBottom: 'none', textDecoration: 'none'}} className={location.pathname.includes('/admin/sobre') ? 'option1' : 'option'}>
                  <span style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', fontSize: 14, fontWeight: 400}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-person" viewBox="0 0 16 16">
                      <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5z"/>
                    </svg>
                    Sobre
                  </span>
                </AccordionTrigger>
                <AccordionContent>
                  <div onClick={() => {
                    navigate('/admin/sobre/sobre')
                  }} className={location.pathname.includes('admin/sobre/sobre') ? 'option3' :'option2'}>Sobre nós</div>
                  <div className={location.pathname.includes('admin/sobre/services') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/services')
                  }}>Serviços</div>
                  <div className={location.pathname.includes('admin/sobre/frota') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/frota')
                  }}>Frota</div>
                  <div className={location.pathname.includes('admin/sobre/diferenciais') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/diferenciais')
                  }}>Diferenciais</div>
                  {/* <div className={location.pathname.includes('admin/sobre/contactos') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/contactos')
                  }}>Contactos</div> */}
                  
                  {/* <div className={location.pathname.includes('admin/sobre/estruturaAdmin') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/estruturaAdmin')
                  }}>Estrutura Administrativa</div>
                  <div className={location.pathname.includes('admin/sobre/history') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/history')
                  }}>Historial</div>
                  <div className={location.pathname.includes('admin/sobre/organigrama') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/organigrama')
                  }}>Organigrama</div>
                  <div className={location.pathname.includes('admin/sobre/estatutos') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/sobre/estatutos')
                  }}>Estatutos e Regulamentos</div> */}

                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <Accordion type="single" collapsible>
              <AccordionItem style={{borderBottom: 'none', textDecoration: 'none'}} value="item-1">
                <AccordionTrigger style={{borderBottom: 'none', textDecoration: 'none'}} className={location.pathname.includes('/admin/transporte') ? 'option1' : 'option'}>
                  <span style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', fontSize: 14, fontWeight: 400}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bus-front-fill" viewBox="0 0 16 16">
  <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.5 2.5 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A44 44 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1zM3.552 3.22A43 43 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44 44 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994M8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0m8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1"/>
</svg>
                    Transporte
                  </span>
                </AccordionTrigger>
                <AccordionContent>
                  <div onClick={() => {
                    navigate('/admin/transporte/urbano')
                  }} className={location.pathname.includes('admin/transporte/urbano') ? 'option3' :'option2'}>Urbano</div>
                  <div onClick={() => {
                    navigate('/admin/transporte/express')
                  }} className={location.pathname.includes('admin/transporte/express') ? 'option3' :'option2'}>Express</div>
                  <div className={location.pathname.includes('admin/transporte/interprovincial') ? 'option3' :'option2'} onClick={() => {
                    navigate('/admin/transporte/interprovincial')
                  }}>Interprovincial</div>

                  

                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <div onClick={() => {
              navigate('/admin/news')
              localStorage.setItem('path', 'home')
            }} className={location.pathname.includes('admin/news') ? 'option1' : 'option'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
  <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5z"/>
  <path d="M2 3h10v2H2zm0 3h4v3H2zm0 4h4v1H2zm0 2h4v1H2zm5-6h2v1H7zm3 0h2v1h-2zM7 8h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2z"/>
</svg>

            Notícias
            </div>
            {/* <div onClick={() => {
              navigate('/admin/avisos')
              localStorage.setItem('path', 'home')
            }} className={location.pathname.includes('/admin/avisos') ? 'option1' : 'option'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
</svg>
            Avisos
            </div> */}
            
        </div>
    )
}
export default AdminSide