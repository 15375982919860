import { useRef, useState } from 'react'
import '../css/newsletter.css'
import Loading1 from './loading1'
import { Dialog, DialogContent, DialogTrigger } from '../@/components/ui/dialog'
import Lottie from 'lottie-react'
const NewsLetter = () => {
    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const whats = useRef(null)
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    const [errors, setErrors] = useState([])
    const addEmail = () => {
        setErrors([])
    }
    return (
        <div className='homeSection' id='newsletter' style={{maxWidth: 1700, paddingBottom: 30}}>
            <Loading1 loading={loading}/>
            <Dialog open={done} onOpenChange={(e) => {
                setDone(e)
            }} >
                <DialogTrigger style={{display: 'none'}}>

                </DialogTrigger>
                <DialogContent style={{width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column',}}>
                <Lottie rendererSettings={
                    {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                    } autoplay loop animationData={require('../components/lottie1.json')}style={{width: 250, height: 250, alignSelf: 'center', marginTop: 40}} className='lottieItem'/>

                    <div style={{textAlign: 'center', fontSize: 20, color: '#495057', fontWeight: '500', marginTop: 30}}>Submetida</div>
                    <div style={{textAlign: 'center', color: 'grey', fontSize: 14}}>Obrigado por assinares ao nosso Boletime Informativo. Fique sempre atento ao seu email para estar atualizado com a AngoTrans</div>

                    <div style={{paddingTop: 20, width: '100%', borderTop: '2px solid #e6e6e6'}}></div>

                </DialogContent>
            </Dialog>
            <div className='description' >Subscreva-se ao nosso boletim</div>
            <div className='title' >Informativo</div>

            <div className='bannerContainer'>
                <img src={process.env.PUBLIC_URL + '/images/bus1.jpeg'} style={{filter: 'brightness(0.3'}}/>

                <div className='info'>
                    <div className='text'>
                        Quer receber mais de nossas notícias? Preencha os campos abaixo e assine nosso Boletim Informativo
                    </div>
                    <div className='forms'>
                    <div className='form'>
                        <div className='label'>Nome</div>
                        <div className='inputContainer' onClick={()=> {
                            nameRef.current.focus()
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg>
                            <input ref={nameRef} onChange={(e)=>{
                            }} placeholder='Nome...' className='loginInput' />
                        </div>
                    </div>
                    <div className='form'>
                        <div className='label'>Email</div>
                        <div className='inputContainer'  onClick={()=> {
                            emailRef.current.focus()
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>
                            <input ref={emailRef} onChange={(e)=>{
                            }} type='email' placeholder='Email...' className='loginInput' />
                        </div>
                    </div>
                    <div className='form' style={{marginBottom: 30}}>
                        <div className='label'>WhatsApp</div>
                        <div className='inputContainer'  onClick={()=> {
                            whats.current.focus()
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
</svg>
                            <input ref={whats} onChange={(e)=>{
                            }} type='tel' placeholder='Ex: 945123456...' className='loginInput' />
                        </div>
                    </div>
                    <div className="errors">
                            {errors.length > 0 && errors.map((item, index) => {
                                return (
                                    <div className="error" key={index}>
                                        {item}
                                    </div>
                                )
                            })}
                            
                        </div>
                    <div className='valueButton'style={{width: 120, padding: 8, fontWeight: 500}}
                    onClick={() => {
                        setLoading(true)
                        setTimeout(() => {
                            setLoading(false)
                            setDone(true)
                        }, 2000);
                    }}>Subscreva-se</div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default NewsLetter